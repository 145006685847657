function HorizontalLineWithText({ text }) {
    return (
        <div className="relative flex items-center">
            <div className="grow border-t border-gray-400" />
            <span className="mx-4 shrink text-gray-400">{text}</span>
            <div className="grow border-t border-gray-400" />
        </div>
    );
}

export default HorizontalLineWithText;
