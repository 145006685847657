import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import * as yup from "yup";

import LoginSVG from "../assets/svg/login.svg";
import { FlashIndividualMessage } from "../components/FlashMessage";
import HorizontalLineWithText from "../components/HorizontalLineWithText";
import RevealPassword from "../components/RevealPassword";
import { useLogin } from "../hooks/useLogin";
import { supabase } from "../utils/supabase";

const initialState = {
    email: "",
    password: "",
};

const schema = yup.object().shape({
    email: yup.string().email("Invalid email address").required("You must enter an email address"),
    password: yup.string().required("You must enter a password"),
});

function LoginAdmin() {
    const location = useLocation();
    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: initialState,
        resolver: yupResolver(schema),
    });
    console.log(location);
    const [revealPassword, setRevealPassword] = useState(false);
    const { login, error, isLoading: isLoginLoading } = useLogin();

    const onSubmit = async (values) => {
        // strip whitespace
        // eslint-disable-next-line no-param-reassign
        values.email = values.email.trim();

        login(values);
    };

    return (
        <div className="mx-auto flex max-w-7xl items-center justify-center p-10 md:space-x-12 md:p-12">
            <img alt="" className="hidden h-auto w-1/2 object-cover lg:block" src={LoginSVG} />
            <div className="mt-12 w-96">
                <h1 className="text-3xl font-bold text-primary">Welcome back</h1>
                <div className="mt-8 space-y-4">
                    <form noValidate className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="relative">
                            <input
                                className="block w-full rounded-md border-2 border-gray-300 p-2 focus:border-primary focus:ring-0"
                                placeholder="Email"
                                type="email"
                                {...register("email")}
                            />
                            {errors.email && <FlashIndividualMessage message={errors.email.message} />}
                        </div>
                        <div className="relative">
                            <input
                                autoComplete="off"
                                className="block w-full rounded-md border-2 border-gray-300 p-2 focus:border-primary focus:ring-0"
                                placeholder="Password"
                                type={revealPassword ? "text" : "password"}
                                {...register("password")}
                            />
                            <RevealPassword revealPassword={revealPassword} setRevealPassword={setRevealPassword} />
                            {errors.password && <FlashIndividualMessage message={errors.password.message} />}
                        </div>

                        <button
                            className="text-xs uppercase tracking-widest text-primary transition duration-150 ease-in-out hover:opacity-70"
                            type="submit"
                        >
                            <Link to="/auth/forgot-password">Forgot Password?</Link>
                        </button>

                        <button
                            className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:opacity-70"
                            disabled={isLoginLoading}
                            type="submit"
                        >
                            {isLoginLoading ? <BeatLoader color="white" size={12} /> : "Login"}
                        </button>
                    </form>
                    <HorizontalLineWithText text="or" />
                    <p className="my-8 text-center text-xs text-gray-500">
                        Don&#39;t have an account?
                        {"    "}
                        <a className="text-primary hover:text-blue-600" href="/auth/signup">
                            Create one
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LoginAdmin;
