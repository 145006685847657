/* eslint-disable max-len */
import "../index.css";

function TermsOfService() {
    return (
        <div className="my-12 flex justify-center">
            <div className="prose prose-sm focus:outline-none sm:prose-base">
                <h1 className="text-center"><strong>服務條款</strong></h1>
                <p>歡迎加入OfferLand！</p>
                <p>請詳閱以下服務條款內容以保障您的權益。</p>
                <p>
                    若您有著作權、財產權、交易與退款、電子商務等疑慮，或其他智慧財產權、法律上權利遭受侵害之疑慮，建議透過以下信箱與我們聯繫：
                    <a className="text-primary" href="mailto:contact@offerland.cc" rel="noopener noreferrer" target="_blank">contact@offerland.cc</a>
                    。
                </p>
                <p>OfferLand（下稱「本網站」）依據《OfferLand 服務條款》（下稱「本條款」）所提供所有 OfferLand 相關產品與服務（下稱「本服務」）的基本約款，規範本網站與用戶的關係。由使用本服務的用戶（下稱「用戶」）與訊洲科技有限公司（下稱「本公司」）間所合意訂定。請您認真閱讀本條款，審閱並接受或不接受本條款（未成年人應在法定監護人陪同下審閱）。於您使用本服務前，請您先完整閱讀並確認您同意本條款的全部內容，如有任何不同意之處，請您切勿使用本服務。如對相關規範有任何不明白處，您可以隨時聯絡我們，以尋求解答。</p>
                <p>當您存取或使用本服務時，即表示您已閱讀、了解且同意遵守本條款下示條款：</p>
                <ol>
                    <li>
                        <h2>名詞定義</h2>
                    </li>
                    <ol className="list-latin">
                        <li>用戶：指使用本服務的用戶，不論其身份為訪客或已註冊之使用者。</li>
                        <li>用戶內容：用戶所創作並上傳、儲存、發布至本網站的著作權、文章及資料，以及由用戶公開張貼或私下傳送的訊息、數據、文字、軟體、音樂、音頻、照片、圖形、影片、代碼或其他資訊等內容。</li>
                        <li>廣告商：即廣告公司，係指從事廣告代理業務，為其客戶創造、規劃、或執行廣告工作，其工作範圍可能涵蓋曝光、内容和戰略等方面，為客戶的產品或服務銷售提供外部服務。</li>
                        <li>合作夥伴：包括但不限於廣告商、第三方服務提供者、本公司客戶、本公司供應商。</li>
                        <li>
                            本網站所直接運營之第三方商城網站：包括但不限於本服務於綠界科技ECShop所架設之線上商城
                            {" "}
                            <a href="https://offerland.cashier.ecpay.com.tw/" rel="noopener noreferrer" target="_blank">https://offerland.cashier.ecpay.com.tw/</a>
                            {" "}
                            。
                        </li>
                        <li>本網站所經營之社群媒體：包括但不限於本網站所經營之部落格、Facebook、Instagram、LinkedIn、Line@、Discord或其他社群媒體。</li>
                        <li>教學媒合：本網站提供之教學媒合服務，包括但不限於留學顧問、文書服務、語言教學媒合等。教學服務完全由第三方服務提供者所提供，而非由本公司提供，本公司將作為平台/媒合方促使第三方提升教學服務之品質。本公司提供之教學媒合服務，並非教學服務協議之任一方當事人。第三方亦非本公司之雇員或代理人。</li>
                        <li>第三方服務提供者：係指教學媒合之第三方服務提供者，包括但不限於老師、潤稿業者、留遊學代辦機構、補習班、顧問等。</li>
                        <li>數位出版品內容：係指本公司發布、上傳或銷售之非以有形媒介提供之數位內容，包括但不限於電子書、線上課程等。</li>
                    </ol>
                    <li>
                        <h2>對於本條款之同意</h2>
                    </li>
                    <ol className="list-latin">
                        <li>用戶應依據本條款之規定使用本服務。</li>
                        <li>如您是未滿十八歲之未成年人或依中華民國法令須事先得第三人同意始得行使權利或負擔義務者，則您必須取得父母、監護人或該第三人同意後，才可以註冊為用戶及使用本服務。當您完成註冊或開始使用服務時，即視為您的父母、監護人或該第三人已經充分審閱、了解及同意本條款條款，並同意您註冊成為用戶及使用服務。本網站僅適用於18歲以上且可根據當法規訂立具有法律拘束力合約的個人使用，於各國法規另有規定時，從其規定。若您未滿18歲以上或未達各國法規規定可獨立行使權利負擔義務之年齡，請經過您的法定代理人同意再使用本網站。若本網站發現您未達前開年齡，本網站有權拒絕您繼續使用本網站。</li>
                        <li>若本條款所規定事項有未盡之處，用戶應遵照本條款之規定使用本服務。</li>
                        <li>本網站透過網頁及行動裝置應用程式等平台提供本服務，本條款適用於所有平台上的用戶。</li>
                        <li>您同意您的用戶內容、本網站帳戶資料及所有您使用本服務時建立、輸入、呈現、提交或以任何方式提供的資訊，在所有用戶使用的所有平台上同步；本網站為進行前述平台同步，得存取、重製（一部或全部）、公開傳輸、公開播送、公開口述、公開展示、改作、散布、處理及/或以其他方式利用前述內容、資料及資訊。</li>
                    </ol>
                    <li>
                        <h2>條款範圍、更新與通知</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本條款適用於本服務於 https://offerland.cc/ 網站域名及次級網域名與各項服務（包括但不限於行動裝置、桌上型電腦等）以及「本網站所直接運營之第三方商城網站」所提供的服務。</li>
                        <li>本條款尚包含於本網站顯示或公告，包括但不限於「本網站所直接運營之第三方商城網站」公告，或經授權由「本網站所經營之社群媒體」上所公告或揭露之用戶守則、使用規範、問答專區、行銷獎勵等政策。用戶亦應同意接受本網站之隱私權政策及其後之更新。</li>
                        <li>基於保障本網站經營與消費者權益之目的，本網站保有不定期更新、修改與補充本條款之權利，每次修改之服務條款均構成本條款之一部份。</li>
                        <li>本條款內容包括條款正文及所有本網站已經發布的各類規則。所有規則為本條款不可分割的一部分，與本條款正文具有同等法律效力。</li>
                        <li>本網站有權隨時變更本條款並在本網站上予以公告。經修訂的條款一經在本網站的公布後，立即自動生效。一旦您繼續使用本網站，即表示您同意受更新後之條款拘束並接受並自願遵守經修訂後的條款。如您不同意相關變更，必須停止使用本網站，請您立即停止使用本網站並聯繫我們協助處理。</li>
                        <li className="font-semibold text-red-500">條款修改通知：本網站保留隨時修改本條款及相關規則之權利，修改後的使用條款將公布在本網站，而無須另行通知用戶。用戶同意遵守修改後之服務條款。用戶須經常主動閱覽修正後之條款，若用戶於條款修正後仍繼續使用，視為接受修正後之條款。</li>
                        <li className="font-semibold text-red-500">服務變更與通知：本網站保留於任何時間點，不經通知隨時修改或暫時或永久停止繼續提供本服務（或其任一部分）的權利。您同意本網站對於您或是任何第三方對於任何修改、暫停或停止繼續提供本服務不負任何責任。如依法或其他相關規定須為服務變更通知時，本網站得以包括但不限於：電子郵件、一般信件、簡訊、多媒體簡訊、文字訊息、張貼於本服務網頁，或其他現在或未來合理之方式通知您。但若您違反本條款，以未經授權的方式存取本服務，您將不會收到前述通知。當您經由授權的方式存取本服務，而同意本條款時，您即同意本網站所為之任何及所有給您的通知，都視為送達。</li>
                        <li>您有義務經常檢視本條款及相關規則之修（增）訂，如您在上述修訂生效以後，仍繼續使用本服務，視為您接受上述修（增）訂。您對於本網站所寄送之一切通知，應儘速查閱。您應定期檢閱本網站之更新公告，並應定期連線至本網站以及查閱相關通知。</li>
                    </ol>
                    <li>
                        <h2>智慧財產權、資料所有權與用戶內容授權</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本網站所有權：本網站、本網站上之一切著作、商標、專利、智慧財產權、其他權利或本網站未申請之權利，均屬於本公司或與本公司合作之第三方所有。本網站所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、數據、網站架構、網站畫面的安排、網頁設計、用戶內容等，均由本網站或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。除非本條款有明確之規定，否則本網站不授予您任何明示或暗示的權利。</li>
                        <li>任何人不得逕自使用、修改、重製、公開播送、公開傳輸、公開演出、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯「本網站所有權」。若您欲引用或轉載前述軟體、程式或網站內容，除明確為法律所許可者外，必須依法取得本網站或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本網站及其他權利人負損害賠償責任。本網站及其合作夥伴為行銷宣傳本服務，就本服務相關之商品或服務名稱、圖樣等（下稱「本網站商標」），依其註冊或使用之狀態，受商標法及公平交易法等之保護，未經本網站事前書面同意，您同意不以任何方式使用本網站商標。</li>
                        <li>若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表用戶內容，並將前述權利轉授權第三人，請勿擅自將該用戶內容上載、傳送、輸入或提供予本網站。</li>
                        <li className="font-semibold text-red-500">您一經上載、傳送、輸入或提供予本網站之任何數據，您得依法享有用戶內容的相關智慧財產權。惟就上述用戶內容及相關智慧財產權，您同意以下事項：</li>
                        <ol className="list-roman">
                            <li className="font-semibold text-red-500">您特此授予本網站及其全球合作夥伴使用之權利，不受任何條件或限制，該權利係不可撤銷、永久、非專屬、可轉讓、可轉授權、免版稅、免權利金且無償的授權，授權地區為全世界，本公司及本網站及其全球合作夥伴依據上述授權得以下列方式使用，包括但不限於，檢視、重製、散布、翻譯、改作、發行、編輯、公開發表、公開口述、公開上映、公開演出、公開展示、公開播送、公開傳輸及其他方式利用用戶內容之全部或一部，並得收錄於資料庫中提供服務，或推廣或行銷本公司及本網站，或維護、提升或修改本網站之功能，或再授權其他人為前述利用的權利。您茲此聲明並保證您就用戶內容及其發佈擁有一切必要的權利或授權，足以有效賦予本公司及本網站前述之授權，不致侵害任何第三人之智慧財產權，否則應對本網站及其合作夥伴負損害賠償責任（包括但不限於訴訟費用及律師費用等）。</li>
                            <li>
                                本公司及本網站保留拒絕接受、張貼、顯示或傳輸用戶內容的權利，並有權依據
                                <a href="https://www.dcard.tw/f/dcard/p/228966454" rel="noopener noreferrer" target="_blank">刪文規則</a>
                                逕行刪除您發佈的用戶內容。
                            </li>
                            <li>您可以主動刪除用戶內容，惟用戶內容業經其他用戶引用部分，不在此限。</li>
                            <li>就上述經刪除之用戶內容，本網站有權且可能已留存備份，但本網站並無備份之義務。就您因用戶內容未儲存或刪除所生之一切風險或損失，您應自行採取防護措施，本網站概不負責，亦無義務將本網站內部留存之備份或檔案（縱有）提供給您。</li>
                            <li>由本網站及其合作夥伴儲存或管理該資料，並由本網站及其合作夥伴之搜尋工具進行索引及抓取，並公開刊登、使用於本網站及其合作夥伴的相關係統網絡上，包括但不限於本網站所聯盟或合作的第三方網站上。</li>
                        </ol>
                        <li>您同意並承諾用戶內容為您所有，且您未侵害任何第三方之權利。若您發現有任何權利侵害之情事，請立即聯繫我們並提供相應之證據資料，本網站不保證刪除。若提供不實證據要求本網站刪除用戶內容，提供者應自負責任。</li>
                        <li className="font-semibold text-red-500">因您經由本服務提供、張貼或傳送用戶內容、使用本服務、與本服務聯機、違反本條款、或侵害其他人任何權利所衍生或導致任何第三人為請求或主張時，您同意使本網站及其子公司、合作夥伴、經理人、代理人、受雇人、合夥人及授權人免於任何損害。由於您經由本服務提供、張貼或傳送之本服務、您使用本服務、您與本服務聯機、您違反本條款、或您侵害其他人任何權利因而衍生或導致任何第三者提出索賠或請求，包括合理的律師費，您同意賠償本網站及其分公司、分支機構、主管、代理人、聯名廠商或其他夥伴及員工，並使其免受損害。</li>
                        <li className="font-semibold text-red-500">隱私期待：您理解並同意，本網站屬於公開平台，且有不特定多數之用戶，您所上傳、儲存、發布、分享之一切資料均於網際網路完全公開，包括但不限於，您的用戶內容、您在本網站發布之評論、您使用本服務時建立、輸入、呈現、提交或以任何方式提供的資訊、您與本網站以及其他用戶通過本網站溝通之記錄、您通過「錄取結果回報系統」上傳之資料，請務必注意自身隱私與資料安全。</li>
                        <li>安全控管機制：</li>
                        <ol className="list-roman">
                            <li>您理解並同意，為維護本網站營運方針及使用安全，本網站得儲存並檢視用戶於本網站的一切活動，包括但不限於您在本網站發布之個人資料、評論、使用本服務時建立、輸入、呈現、提交或以任何方式提供的資訊、您與本網站之溝通歷程，以及您與其他用戶通過本網站溝通之記錄等，無論係文字、圖片或影音資料。您理解並同意，本網站得用前開資料作為法律上爭端解決之依據，並得將前開資料用於平台內部之分析及優化，以提供您更優質的服務。其中涉及個人資料之蒐集、處理、利用部分，請參閱本網站之「隱私權政策」。</li>
                            <li>您得請求本網站移除您上傳、儲存、發布之資料，惟此請求可能導致您不能再使用本網站。</li>
                            <li>您理解並同意，如遇用戶內容發生版權爭議或有妨害其他用戶使用本網站之情事，本網站保留自行判斷並暫停或移除您所上傳、儲存、發布資料之權利，亦保留自行判斷暫停或終止您訪問或使用本網站一部或全部之權利。您不得就本網站暫停或移除您所上傳、儲存、發布資料，或就終止您使用本網站之行為請求任何損害賠償。</li>
                        </ol>
                    </ol>
                    <li>
                        <h2>註冊和帳號使用</h2>
                    </li>
                    <ol className="list-latin">
                        <li>就註冊程序所要求的真實姓名及其他資料，您提供的資訊皆為正確、最新及完整的資料，且應維持並更新您的個人資料，並應依本網站之請求適時更新。若您提供任何錯誤、不實或不完整的資料，本網站有權暫停或終止您的帳號，並拒絕您使用本服務之部分或全部。</li>
                        <li>您應妥善保管本網站之帳號並維護帳號安全，若發生任何錯誤、盜用之情事，您應盡速通知本網站處理，並應協力提出佐證，供本網站釐清事實。當您密碼或帳戶有遭他人冒用、盜用或其他未經授權使用，或有任何安全性漏洞時，您同意立即通知本網站。若您遭受任何損害，您應自行承擔，本網站概不負責。</li>
                        <li>您有自行妥善保管您留存於本公司系統之帳號及密碼之義務，不得將該帳號及密碼透露或提供給第三人知悉、或出借或轉讓第三人使用。經您通知他人盜用情形後所發生的行為外，對於所有使用該組帳號及密碼登入本服務系統所為之一切行為，均視為您本人或經您授權之行為。</li>
                        <li>本網站保留與用戶締結本條款之權利。</li>
                        <li>用戶可隨時經申請來刪除本網站帳號。另外，當本網站合理認為用戶違反本條款時，得在不事先通知用戶的情形下刪除該帳號，用戶之帳號一旦經本網站刪除，即無法再進行申訴與申請任何本網站帳號。</li>
                        <li>不論基於何種原因（包括但不限於用戶故意或不慎刪除本網站帳號之情形），自本網站帳號刪除時起，用戶原有本網站帳號均無法回復，且用戶於其帳號下的所有權利均將消除，本服務中之用戶資料亦將刪除。用戶同意並瞭解，本網站就用戶因帳號刪除所受之損失或不便，不負任何責任。請注意：刪除帳號後，用戶內容不會自動刪除，若有必要請事先以手動方式刪除。</li>
                        <li>您同意使用服務之所有內容包括意思表示等，皆以電子文件做為表示方式，且本網站對您的所有通知皆以電子郵件、本網站通知系統或其他電子方式為之。</li>
                        <li>本網站所提供之用戶帳號，僅限個人使用。您不得同時註冊或使用兩個以上的帳號，或以任何有償、無償等方式，將帳號提供於第三人使用。如果您的帳號遭停權，您不得註冊另一個帳號，且本網站就違規帳號保有直接刪除的權利。</li>
                        <li>您於使用本服務部分功能時，需以經本網站審核後可供用戶註冊之學校信箱完成認證，才能完整使用本服務；若您使用非學校信箱註冊，部分功能將限制使用。</li>
                        <li>您理解並同意，任何以您的帳號於本服務中所為行為，均視為您本人或經您授權所為，您可能因此必須負擔相應的法律責任，因此您不會讓其他人透過您的帳號使用本服務，亦不會將您的帳號密碼透露與他人知悉。</li>
                        <li>您理解並同意，本網站為一開放性社群平台，一般用戶均可自由閱覽本網站的所有公開頁面，本網站無法防止第三人將用戶內容與您聯想在一起，因此您於發表文章、回應或與其他用戶對話時，均應注意您所揭露的資訊可能導致陌生人或您的親友足以辨識您的真實身份，而可能對您的現實生活產生影響，您應自負其責。</li>
                    </ol>
                    <li>
                        <h2>ID、暱稱設定與使用</h2>
                    </li>
                    <p>就您對於本網站及暱稱的設定與使用，您已詳細瞭解，並聲明、保證以下事項：</p>
                    <ol className="list-latin">
                        <li>您認知，如您以您於其他網站、通訊軟體使用之帳號、名稱、暱稱等於您的本網站 ID 及暱稱，將容易使您的身份被連結、搜尋及勾稽，您應自負其責。</li>
                        <li>
                            無論出於故意或疏失，您不會以與已註冊商標（
                            <a href="https://twtmsearch.tipo.gov.tw/" rel="noopener noreferrer" target="_blank">智慧局商標檢索系統</a>
                            ）或他人商品或服務之表徵等相關之任何名稱作為自己的 ID 或暱稱，就此本網站有權以自己之判斷決定收回您的 ID 或暱稱並交予權利人使用，您不得有任何異議。
                        </li>
                        <li>您不會以任何影射、謾罵、毀謗、冒用他人等違反網路禮節、社會秩序或違法之文字作為您的 ID 及暱稱。本網站有權以自己之判斷決定註銷具有上述性質的 ID 及暱稱，您不得有任何異議。</li>
                        <li>您不會以疑似本網站官方的文字作為您的 ID 及暱稱，若名稱含有特殊字元、空白字元或特殊符號或類似本網站人員名稱，本網站有權直接強制更名。</li>
                        <li>違反相關規定時，本網站有權判斷，您同意完全遵循本網站之決定。</li>
                    </ol>
                    <li>
                        <h2>隱私權政策</h2>
                    </li>
                    <p>關於您的用戶註冊以及其他特定數據依本網站「隱私權政策」受到保護與規範。您了解當您使用本服務時，您同意本網站依據「隱私權政策」進行您個人資料的搜集與利用，包括跨國間的傳輸與儲存，及本公司及其合作夥伴內部之利用。本公司將會以安全的方式管理自用戶處蒐集的資料，並對安全管理採取嚴格的措施。</p>
                    <li>
                        <h2>與第三方網站的連結及第三方提供之內容</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本服務或第三方可能會提供連結至其他網站或網絡資源的連結。您可能會因此連結至其他業者經營的網站，但不表示本網站與該等業者有任何關係。其他業者經營的網站均由各該業者自行負責，不屬本網站控制及負責範圍之內。本網站對任何檢索結果或外部連結，不擔保其合適性、可依賴性、實時性、有效性、正確性及完整性。您也許會檢索或連結到一些不合適或不需要的網站，遇此情形時，本網站建議您不要瀏覽或儘速離開該等網站。您並同意本網站無須為您連結至前述非屬於本服務之網站所生之任何損害，負損害賠償之責任。 本服務隨時會與其他「第三方服務提供者」或「廣告商」合作，由其提供包括廣告、訊息、活動等不同內容供本服務刊登，且本服務於刊登時均將註明內容來源。基於尊重「第三方服務提供者」和「廣告商」之智慧財產權，本服務對其所提供之內容並不做實質之審查或修改。對該等內容之正確真偽，您宜自行判斷之，本部對該等內容之正確真偽不負任何責任。您若認為某些內容屬不適宜、侵權或有所不實，請逕向該「第三方服務提供者」和「廣告商」反應意見。</li>
                        <li className="font-semibold text-red-500">您在本網站中瀏覽到的所有廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，均由各該廣告商所設計與提出。您對於廣告之正確性與可信度應自行斟酌與判斷。本網站僅接受委託予以刊登，不對前述廣告負擔保責任。</li>
                        <li>您瞭解為了永續經營，本網站接受廣告業務，您並同意在使用本網站服務時接受廣告和其他行銷內容。我們可能在您存取內含網路信標、開發工具、Cookie 和其他技術資料蒐集資訊。這些資料蒐集技術有助於我們瞭解您的活動情形，以改進我們的產品與服務，並提供您您有興趣的廣告資訊。這些資訊包括所提供、觀看或點選的內容或廣告類型；您的活動頻率與使用持續時間；您是否與特定內容或廣告有所互動；以及您是否接著造訪廣告商的網站、下載廣告商的應用程式、購買廣告中的產品或服務或者採取其他行動。</li>
                        <li>為了提供對您而言有價值的廣告和其他行銷內容，您同意本網站可以分析您的個人資料、用戶內容及帳戶資料，予以分類，將無法直接辨識您個人之資訊或族群類別提供予廣告商，以便廣告商可以選擇要投放廣告的族群類別。</li>
                        <li>本網站從事上述廣告業務將收取廣告收入，但無須給您任何報酬。</li>
                        <li>本網站可能會與廣告商共同辦理行銷活動，並可能邀請您參與。您於參加此類行銷活動中，可能必須另外同意該活動之活動辦法，且可能必須將您的個人資料提供予該活動之合作夥伴，亦即，您可能因參與活動，而與該第三方廠商間產生獨立的契約關係，您應於參加前詳讀參加條款，並瞭解第三方廠商可能如何利用您的個人資料。您理解並同意，本網站 無法介入您與第三方廠商間的法律關係。</li>
                    </ol>
                    <li>
                        <h2>兒童及青少年之保護</h2>
                    </li>
                    <p>兒童及青少年上網已經成為無可避免之趨勢，使用網際網路獲取知識更可以培養子女的成熟度與競爭能力。然而網絡上的確存有不適宜兒童及青少年接受的訊息，例如色情與暴力的訊息，兒童及青少年有可能因此受到心靈與肉體上的傷害。因此，為確保兒童及青少年使用網絡的安全，並避免隱私權受到侵犯，家長（或監護人）應盡到下列義務：</p>
                    <ol className="list-latin">
                        <li>先檢閱各該網站是否有保護個人資料的隱私權政策，再決定是否同意提出相關的個人資料；並應持續叮嚀兒童及青少年不可泄漏自己或家人的任何數據（包括姓名、地址、電話、電子郵件信箱、照片、信用卡號等）給任何人。也不可以單獨接受網友的邀請或贈送禮物而與之見面。</li>
                        <li>謹慎選擇合適網站供兒童及青少年瀏覽。未滿十二歲之兒童上網時，應全程在旁陪伴，十二歲以上未滿十八歲之青少年上網前亦應斟酌是否給予同意。</li>
                    </ol>
                    <p>當您使用或繼續使用本服務時，即推定家長（或監護人）已閱讀、瞭解並同意接受本條款之所有內容及其後修改變更。若該未成年用戶與相對人或其他第三人，因使用本服務所發生之糾紛，本公司概不負責。</p>
                    <li>
                        <h2>用戶的守法義務及承諾</h2>
                    </li>
                    <p>您承諾絕不為任何非法目的，或以任何非法方式使用本服務，並承諾遵守中華民國相關法規，及一切使用網際網路之國際慣例。您若係中華民國以外之用戶，並同意遵守所屬國家或地域之法令。您同意並保證不得利用本服務從事侵害他人權益或違法之行為，如您不同意下列任一義務，請立即停止使用本網站。用戶義務包括但不限於：</p>
                    <ol className="list-latin">
                        <li>您不得於本網站公開上傳，或以私訊的方式傳送，或上載、張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、辱罵、非法、違反公共秩序或善良風俗或其他引起他人反感之內容或行為於本服務上。</li>
                        <li>您不得侵害本網站、本網站用戶之權利，包括侵害他人之名譽、隱私權、營業秘密、商標權、著作權、商標、專利權、其他智慧財產權及其他權利。您不得因種族、階級、語言、思想、宗教、黨派、籍貫、出生地、性別、性傾向、年齡、婚姻、容貌、五官、身心障礙、星座、血型等理由對本網站用戶有歧視之言語或行為。</li>
                        <li>違反依法律或契約所應負之保密義務。</li>
                        <li>冒用他人名義使用本服務。</li>
                        <li>上載、張貼、傳輸或散布任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程序代碼之數據。散播電腦病毒或惡意程式，藉由網路流量或傳輸量癱瘓本網站或服務或造成系統過大負荷，或任何可能或足以干擾、中斷或影響本服務正常提供之行為。</li>
                        <li>從事不法交易行為或張貼虛假不實、引人犯罪之訊息。濫發廣告郵件、垃圾郵件、連鎖信、違法之多層次傳銷訊息等。任何涉及騷擾、猥褻、威脅、詐欺、霸凌、侵害他人權益或濫用本服務之行為。</li>
                        <li>以任何方法傷害未成年人。</li>
                        <li>偽造訊息來源或以任何方式干擾傳輸來源之認定。干擾或中斷本服務或服務器或連結本服務之網路，或不遵守連結至本服務之相關需求、程序、政策或規則等，包括但不限於：使用任何設備、軟體或刻意規避本網站之排除自動搜尋之標題（Robot Exclusion Headers）。</li>
                        <li>以自動化登入及操作方式蒐集本網站其他用戶的用戶內容。或利用本服務，以違反個人資料保護法之方式蒐集、處理及利用本網站其他用戶之帳號、密碼或其他帳戶資訊。</li>
                        <li>非經本網站事前書面同意，擅自使用或複製本網站的商標或其他智慧財產權，或移除、遮掩或變動本網站商標或智慧財產權之標示或其聲明。</li>
                        <li>將您註冊之服務帳號、本服務或其使用權限之任何部分，出售、出借、贈與、轉讓或提供給他人使用或與他人共用。</li>
                        <li>破解或以各種方式繞過任何本網站用來防止或限制全部或部分使用服務的措施，或以解碼、破譯、反編譯、逆向工程或其他方式，試圖獲得任何本服務、其網站或相關程式之原始碼或程式碼。</li>
                        <li>您應自行負擔用於本服務所使用之電子產品、器材設備、網路連接之費用，並應維持其可用性。如因非可歸責於本網站之原因致您無法順利上課或授課，本網站恕不負擔因其所造成之費用及／或損失，您亦不得請求本網站退還該部分課程費用。</li>
                        <li>您不得干擾本網站之功能及其運作，不得以機器人帳戶或其他技術方法，變更、刪除、破壞本網站之資料或設備，您亦不得藉由本網站設計存在之漏洞，或以越過、破壞本網站安全維護機制等其他技術方法，變更、刪除或蒐集平台上之資料，或以任何方式對本網站之經營造成不良影響。</li>
                        <li>您不得以任何方式濫用本網站提供之獎勵、優惠券，使自己或他人獲取不法利益，或以任何非本網站明確允許之方式使用本網站提供之行銷與獎勵政策。</li>
                        <li>於有款項爭議或退費之情事，您應盡力配合提供相關資訊予本網站進行查核，並配合向銀行、金融機構或相關單位完成一切查核程序。</li>
                        <li>您不得嘗試將其他用戶導出本網站，亦不得要求私下交易。</li>
                        <li>您應維護評價機制之功能及其運作，不得以機器人帳戶或其他技術方法，影響用戶之評價，或以利益交換、威脅恐嚇或其他不正方式，影響他人之自由評價。</li>
                        <li>您應依誠實信用原則遵守本條款之規範，並應遵守您所在地之當地法令，不得有損及本網站、其他用戶或第三人之行為。</li>
                        <li>其他經本網站通知您停止之行為，或其他本網站有正當理由認為不適當之行為。</li>
                    </ol>
                    <li>
                        <h2>本服務的提供</h2>
                    </li>
                    <ol className="list-latin">
                        <li>對於您使用本服務或經由本服務連結之其他網站而取得之資訊或建議（包括但不限於，商務、教育、移民、考試、投資理財、醫療、法律等方面），不擔保其為完全正確無誤。本網站對於本服務所提供之資訊或建議有權隨時修改或刪除。您在做出任何相關規劃與決定之前，仍應請教專業人員針對您的情況提出意見，以符合您的個別需求。</li>
                        <li>本網站隨時會與廣告商或第三方服務提供者合作，由其提供包括文章、訊息、圖片等不同內容供本網站刊登。基於尊重廣告商或第三方服務提供者之智慧財產權，本網站對其所提供之內容，若無涉及謾罵與人身攻擊，並不做實質之審查或修改，對該等內容之正確真偽亦不負任何責任。對該等內容之正確真偽，您應自行判斷之。您若認為某些內容涉及侵權或有所不實，請徑向該廣告商或第三方服務提供者反應意見。</li>
                        <li>本網站為一主打共享同儕經驗和專業知識的職涯、留學、教學平台。服務宗旨係透過網際網路設備，提供用戶使用平台之搜尋、篩選與通訊功能。本網站之服務項目包括但不限於電子書及線上課程銷售、資訊軟體服務、教學媒合服務、聯盟行銷、費用代收代付服務、師資評價服務，以及其他本網站隨時更新之服務。</li>
                        <li>基於教學媒合之服務內容及商業模式，您理解並同意本網站在提供教學媒合服務時，本網站並非與用戶或與「第三方服務提供者」訂立協議之一方。您與「第三方服務提供者」之間一切行為，亦皆與本網站無涉，如因此遭受任何損害，均不得向本網站求償。用戶仍應自行判斷「第三方服務提供者」之師資、教材等是否合乎自身要求。本網站無法控制用戶選擇哪位「第三方服務提供者」，也無法控制「第三方服務提供者」使用之教材、教學時間，亦無法保證「第三方服務提供者」將持續在本網站授課，惟用戶與「第三方服務提供者」發生爭議時，本網站將協助釐清爭議並協助處理。</li>
                        <li>您理解並同意，本網站無法替用戶的言論及行為負責，用戶亦非本網站之雇員或代理人，非經本網站之書面同意，用戶均不得代表本網站為任何法律行為。</li>
                        <li>本網站在此授予用戶之權利具有個人、無償、不可轉讓及非專屬之性質，並以使用本服務為唯一目的。縱使本服務介面顯示「購買」、「販賣」或其他類似意義之文字，本公司對用戶所提供的應用程式及本服務相關權利亦不因此移轉給用戶。</li>
                        <li>用戶使用本服務之一切權利不得轉讓予第三人或由第三人繼受。</li>
                        <li>本服務中之用戶內容是由用戶提供，本網站不保證用戶內容之正確性。如用戶內容侵害第三人權利或違反法令，致本網站受第三人追償或受主管機關處分時，本網站得請求用戶賠償本網站因此所受之一切損失及費用。</li>
                        <li>如本網站判斷有必要時，得在不事先通知用戶的情形下隨時暫停、變更或終止本服務之全部或一部。如因暫停、變更、終止本服務而使用戶受有任何損害及損失，本公司不負賠償或補償之責。</li>
                        <li>您同意本網站得就本服務訂定一般措施及限制，包含但不限於本服務將保留電子郵件訊息、用戶內容或其他上載內容之最長期間、本服務一個帳號當中可收發電子郵件訊息的數量限制、本服務一個帳號當中可收發電子郵件訊息的最大檔案、不得將電子郵件信箱提供予不特定之多數人使用或分享檔案、本網站伺服器將為您分配的最大空間，以及一般特定期間內您使用本服務之次數上限（及每次使用時間之上限）。若本網站將本服務維持或傳送之任何訊息、通訊和用戶內容刪除或未予儲存，您同意本網站毋須承擔任何責任。您亦同意，長時間未使用的帳號，本網站有權刪除該帳號。您也同意，本網站有權依其自行之考慮，不論通知與否，隨時變更這些一般措施及限制。本網站實時通訊將提供您及您所通訊的對象，在用戶於本網站伺服器之本網站帳號中儲存對話內容。亦即，您可以透過任何可連結至網際網路的裝置，存取及搜尋您的歷史訊息。無論您是否使用此一功能，其他用戶可選擇使用此一功能在他們於本網站的帳號儲存與您的對話。您對本條款的同意，即代表您允許本網站在其伺服器上儲存這些通訊內容。</li>
                    </ol>
                    <li className="font-semibold text-red-500">
                        <h2>免責聲明及賠償</h2>
                    </li>
                    <ol className="list-latin font-semibold text-red-500">
                        <li>您明確了解並同意：本服務及其應用軟體不提供任何明示或默示的擔保，包含但不限於權利完整、商業適售性、特定目的之適用性及未侵害他人權利。</li>
                        <li>本服務及該軟體乃依其「現狀」及「提供使用時」之基礎提供，您使用本服務及該軟體時，須自行承擔相關風險。本公司不保證以下事項：</li>
                        <ol className="list-roman">
                            <li>本服務及該軟體將符合您的需求。</li>
                            <li>本服務及該軟體不受干擾、及時提供、安全可靠或無錯誤。</li>
                            <li>由本服務及該軟體之使用而取得之結果為正確或可靠。</li>
                            <li>您經由本服務取得之任何資訊或其他資料將符合您的期望。</li>
                            <li>任何軟體中之錯誤將被修正。</li>
                        </ol>
                        <li>是否經由本服務及軟體之使用下載或取得任何資料，應由您自行考量且自負風險，並拋棄因前開任何資料之下載而導致您電腦系統、網路存取、下載或播放設備之任何損壞或資料流失，對本服務提出任何請求或採取法律行動，您應自負完全責任。</li>
                        <li>您自本服務或經由本服務或軟體取得之建議和訊息，無論其為書面或口頭，均不構成本服務或軟體之保證。</li>
                        <li>您明確了解並同意本服務及其子公司、合作夥伴、經理人、受雇人、代理人、合夥人及授權人，無須為您任何直接、間接、附隨、特別、衍生、懲罰性的損害負責，包括但不限於因下述事由所生利潤、商譽、使用、數據之損害或其他無形損失（即令本服務曾被告知該等損害之可能性亦同）：</li>
                        <ol className="list-roman">
                            <li>使用或無法使用本服務。</li>
                            <li>替代商品及服務之購買成本。</li>
                            <li>他人未經授權存取或修改您的傳輸或數據。</li>
                            <li>任何第三人就本服務所為之聲明或行為，或任何其他與本服務有關者。</li>
                        </ol>
                        <li>提供資訊與言論的合法性請自行負責，網站管理員和用戶有權檢舉及移除一些受爭議的內容。我們沒有責任查閱所有在本服務之留言及內容，所以本網站不會負起任何法律責任。本服務所有內容由用戶發表，本服務對所有內容的真實性、完整性及立場等，不負任何法律責任。所有內容只代表發文者個人意見，並非本服務之立場，用戶應自行判斷內容之真實性，若內容涉及人身攻擊違反使用原則，本服務有權立即刪除該內容。</li>
                        <li>若有第三人違反您的智慧財產權之相關權益時，皆與本網站無涉，本網站亦無須負起任何法律責任。如因此遭受任何損害，均不得向本網站求償。</li>
                        <li>本網站的用戶內容審查原則以「是否具參考價值」為基準，如有不適當或對內容出處有疑慮，請聯絡本網站告知，本網站將在最短時間內進行核實與處理。本網站上之所有內容，本網站對內容的真實性、完整性及立場等，不負任何法律責任，若內容涉及人身攻擊違反使用原則，本網站有權立即刪除該討論之內容；若該問答或討論之內容無違反使用原則，但仍對內容有疑慮，本網站將協助通知發文者，由發文者自行斟酌內容的撤除與編輯。</li>
                        <li>如有任何第三人因您利用本網站服務之行為或因您發佈之用戶內容而向本網站或其合作夥伴（包括現任或前任受僱人、經理人、董事等）求償，您必須負責賠償並保障本網站及其合作夥伴（包括現任或前任受僱人、經理人、董事等）免受一切相關損害、損失和費用（包括法律服務費用和其他成本）。</li>
                        <li>
                            本網站是一個網路平台，雖然本網站會盡商業上合理努力，依據本條款及相關規定、
                            <a href="https://www.dcard.tw/f/dcard/p/228966454" rel="noopener noreferrer" target="_blank">刪文規則</a>
                            及其他本網站規則管理用戶行為及用戶內容，但您瞭解本網站無法完全免除您在本網站上遇到其他用戶或任何第三人侵害您權利的情形，或接觸到其他用戶或第三人所發表，具有冒犯性、或不妥之內容，您知悉並同意在任何情況下，本網站均不為任何用戶公開或私下發佈、傳輸、寄送或進行之用戶內容、訊息或任何行為負責，亦不對前述用戶內容或訊息之正確性、完整性或內容品質為任何保證。所有因前述用戶內容或行為所生之損害，包含但不限於任何傳輸錯誤或遺漏，以及經由前述內容所衍生之任何損失等，均由您自行承擔，且本網站（包括其現任或前任受僱人、經理人、董事等）無須為上述情形負責。
                        </li>
                        <li>本網站服務是以「現狀」以及「目前可得」狀態提供。除本條款明示規定者外，本網站均不對服務做出任何明示或默示的保證，亦不保證服務的安全性、不保證您隨時隨地均可獲得服務、不保證服務中任何缺陷或錯誤都將得到更正，也不保證您使用服務的結果將滿足您的需求。您因使用服務所生之一切利益或損失，均由您自行承擔。</li>
                        <li>本服務有時可能會因系統故障而出現中斷、遲延或故障等現象，或可能造成您使用上的不便、數據喪失、錯誤、遭人篡改或其他經濟上損失等情形。本網站將盡商業上合理努力修復系統，但您於使用本服務時應自行採取防護措施；且本網站為維護良好服務品質，將不定期檢修系統設備，檢修期間得暫時停止系統服務。對於您因使用（或無法使用）本服務而造成的損害，本網站不負任何賠償責任，您亦同意就上述事件均不得向本網站求償。</li>
                        <li>您透過本服務瀏覽到的所有廣告及行銷內容，是由廣告商所設計與提出，本網站僅接受廣告商委託予以刊登，不對前述廣告及行銷內容負任何擔保責任。當您點擊本網站或網站中的廣告頁面時，將會連結到第三方網站，該第三方網站有可能蒐集您的資料、IP 位址或以 Cookie 等技術蒐集您的資料，本網站不負責保障您在第三方網站或使用其服務的隱私權。您於第三方網站或與相關廠商之間一切行為，亦皆與本網站無涉，如因此遭受任何損害，均不得向本網站求償。</li>
                        <li>本網站對於因經營考量而於本網站所刊載，非本網站所屬之第三方頁面、廣告或其他一切之線上資訊，其真實性、安全性、準確性與是否合於法令之規定，不負擔保責任。</li>
                    </ol>
                    <li>
                        <h2>用戶行為與內容</h2>
                    </li>
                    <ol className="list-latin">
                        <li className="font-semibold text-red-500">您應為您服務帳戶下的所有活動及發表的用戶內容負責。用戶內容均由「用戶內容」提供者自負責任。本服務無法控制經由本服務而張貼之用戶內容，因此不保證其正確性、完整性或品質。您了解使用本服務時，可能會接觸到令人不快、不適當、令人厭惡之用戶內容。在任何情況下，本服務均不為任何用戶內容負責，包含但不限於任何錯誤或遺漏，以及經由本服務張貼、發送電子郵件或傳送而衍生之任何損失或損害。</li>
                        <li>您了解本服務並未針對用戶內容事先加以審查，但本服務有權（但無義務）依其自行之考慮，拒絕或移除經由本服務提供之任何用戶內容。在不限制前開規定之前提下，本服務及其指定人有權將有違反本條款或法令之虞、或令人厭惡之任何用戶內容加以移除。您使用任何用戶內容時，就前開用戶內容之正確性、完整性或實用性之情形，您同意必須自行加以評估並承擔所有風險。</li>
                        <li>您了解並同意，本服務依據法律的要求，或基於以下目的之合理必要範圍內，認定必須將您的帳號資訊或「用戶內容」加以保存或揭露予政府機關、司法警察或未成年人之監護人時，得加以保存及揭露：</li>
                        <ol className="list-roman">
                            <li>遵守法令或政府機關之要求。</li>
                            <li>為提供本服務所必須者。</li>
                            <li>為防止他人權益之重大危害而有必要者。</li>
                            <li>為免除用戶及公眾之生命、身體、自由、權利、財產上之急迫危險者。</li>
                        </ol>
                        <li>您了解本服務及本服務所包含之軟體，可能含有使數字數據得以被保護之安全組件，使用該等數據必須依本服務或提供該內容予本服務之廣告商所定的使用規則。您不得試圖破壞或規避任何內含於本服務之使用規則。任何未經合法授權之重製、發行、散布或公開展示本服務所提供之數據之全部或一部，均被嚴格禁止。</li>
                        <li className="font-semibold text-red-500">「用戶內容之限制」；用戶內容不得包含下述資訊，包括但不限於：</li>
                        <ol className="list-roman font-semibold text-red-500">
                            <li>
                                <a href="https://www.dcard.tw/f/dcard/p/228966454" rel="noopener noreferrer" target="_blank">刪文規則</a>
                                、本網站中禁止之內容。
                            </li>
                            <li>兒童或少年為性交、猥褻行為之圖畫、照片、影片或其他相類物品。</li>
                            <li>足以引誘、媒介、暗示或其他促使人為性交易之訊息。</li>
                            <li>
                                足以引誘、媒介、暗示或其他使兒童或少年有遭受兒童及少年性剝削防制條例第 2 條第 1 項第 1 款至第 3 款之虞之訊息。
                            </li>
                            <li>發布之內容與兒童或青少年涉刑事案件相關，且記載相關當事人之姓名或其他足以識別其身份之資訊。</li>
                            <li>侵害他人智慧財產權、肖像權、隱私權或其他權利之內容。</li>
                            <li>違反本條款或鼓吹他人違反本條款之內容。</li>
                            <li>違反法律強制或禁止規定之內容。</li>
                            <li>違反公共秩序或社會善良風俗之內容。</li>
                            <li>其他本網站認為應刪除之內容。</li>
                        </ol>
                    </ol>
                    <li>
                        <h2>不得為商業使用</h2>
                    </li>
                    <ol className="list-latin">
                        <li>您同意不對本服務任何部分或本服務（包括：用戶內容、錄取結果、廣告、軟體及本網站帳號等）之使用或存取，進行重製、拷貝、出售、交易、轉售或作任何商業目的之使用。</li>
                        <li>未經本網站事前書面同意，您同意並保證不得利用本服務從事商業行為或用做商業用途。</li>
                        <li>您不得在未經本網站書面同意之情況下，為自己或他人於本網站上進行營利行為或非營利行為之宣傳。</li>
                    </ol>
                    <li>
                        <h2>服務條款之終止</h2>
                    </li>
                    <ol className="list-latin">
                        <li>您同意本網站得依其判斷因任何理由，包含但不限於一定期間未使用、法院或政府機關命令、本服務無法繼續或服務內容實質變更、無法預期之技術或安全因素或問題、您所為詐欺或違法行為、未依約支付費用，或其他本網站認為您已經違反本條款的明文規定及精神（包括但不限於第 13 條 e 項「用戶內容之限制」），而終止或限制您使用帳號（或其任何部分）或本服務之使用，並將本服務內任何「用戶內容」加以移除並刪除。</li>
                        <li>您並同意本網站亦得依其自行之考慮，於通知或未通知之情形下，隨時終止或限制您使用本服務或其任何部分。您承認並同意前開終止或限制，本網站得立即關閉、刪除或限制存取您的帳號及您帳號中全部或部分相關數據及檔案，及停止本服務全部或部分之使用。此外，您同意若本服務之使用被終止或限制時，本網站對您或任何第三人均不承擔責任。</li>
                        <li>本網站得基於任何原因（例如您違反本條款、日後如有收取費用而您無法及時支付費用或帳戶有一段時間沒有任何活動等），本網站有權隨時終止本條款，停止向您提供全部或部分本網站服務或終止您的帳戶。終止後，您的帳戶將會關閉，而且您不得再以該帳戶存取、使用或取回本網站服務或該帳戶中的任何檔案或內容。</li>
                        <li>用戶得隨時向本網站終止本條款，惟於終止前已成立之訂單及協議，並不因終止權之行使而失其效力。</li>
                        <li>本網站在繼續履行的範圍內，仍得向用戶收取使用平台之服務費及手續費。</li>
                        <li>為求終止點前已成立之協議能繼續履行，本網站得視具體情況於終止後一定期間內，對已終止本條款之用戶開放或部分開放本網站之各式功能，並維持用戶於本網站所刊載、登錄之各式資訊。本網站並有權利保留並利用用戶於終止服務前所留下之用戶內容。</li>
                        <li>您可以隨時向本網站申請刪除您的本網站帳號；當您申請刪除您的本網站帳號時，視為您已向本網站通知終止本條款。</li>
                        <ol className="list-roman">
                            <li>您理解並同意，刪除帳號及終止本條款之效力將於您提出申請14日後生效，於生效前，將有14天的時間做為緩衝，若您希望取消刪除帳號，請在14天內聯繫本網站取消刪除。</li>
                            <li>在刪除帳號資料後，您的帳號將無法登入本網站。刪除帳號申請不包含移除用戶內容，如需刪除以上內容，請於提出刪除帳號的申請後14日內自行刪除。14日後帳號將逕行刪除，而您仍保留的文章、留言等內容將以發文時的名義，如：學校、系所或暱稱的方式保留在網站上，以避免破壞整體內容的流暢度。</li>
                            <li>待14天緩衝期滿後，本網站會刪除您的好友及對話紀錄。因應公務機關調閱帳號犯罪紀錄之必要，您的姓名、生日等個人基本資料將保留 30 天後刪除。於 30 日後，本網站仍將以無法直接識別您個人的方式，繼續保留個人的手機、Email、ID 等不重複資料，確保不會再次註冊使用。</li>
                        </ol>
                        <li>無論本條款因何原因終止，本條款下述條款於終止後仍有效力：</li>
                        <ol className="list-roman">
                            <li>第4條 智慧財產權、資料所有權與用戶內容授權</li>
                            <li>第7條 隱私權政策</li>
                            <li>第10條 用戶的守法義務及承諾</li>
                            <li>第11條 本服務的提供</li>
                            <li>第12條 免責聲明及賠償</li>
                            <li>第14條 不得為商業使用</li>
                            <li>第15條 服務條款之終止</li>
                            <li>第17條 電子商務服務</li>
                            <li>第18條 交易與退款政策、損害賠償責任與上限</li>
                            <li>第19條 違反本條款之效果</li>
                            <li>第20條 準據法及管轄法院</li>
                        </ol>
                    </ol>
                    <li>
                        <h2>檢舉、申訴與著作權侵害處理</h2>
                    </li>
                    <ol className="list-latin">
                        <li>用戶若發現其他用戶內容，與個人資訊有違反本條款之禁止事項時，得向本網站提出檢舉。本網站得審閱被檢舉之投稿內容。</li>
                        <li>若投稿違反「用戶內容之限制」或本條款之禁止事項時，本網站有權對該投稿用戶採取刪除投稿、限制利用本服務、停止或刪除本網站帳號等懲處措施。</li>
                        <li>本網站尊重他人著作權，亦要求我們的用戶同樣尊重他人著作權，您了解並同意我們將保護著作權並處理著作權侵害之事宜。若您認為本網站網站中之任何網頁內容或網友使用本網站服務已侵害您的著作權，請您提供相關數據及聲明並經簽名或蓋章後寄送予本網站。您使用本服務若有3次涉及侵權情事者，本網站將終止您全部或部分服務。又若您所涉及侵權情事嚴重者，本網站亦得暫停或終止您全部或部分服務。</li>
                        <li>您同意本網站可以針對用戶違反本條款之行為，按下述機制處置：</li>
                        <ol className="list-roman">
                            <li>
                                如有任何人依據檢舉機制向本網站檢舉您的用戶內容違反本條款、本網站所發布之發文規定或符合
                                <a href="https://www.dcard.tw/f/dcard/p/228966454" rel="noopener noreferrer" target="_blank">刪文規則</a>
                                等規定，本網站在收到前述檢舉後，得依據本網站之裁量進行審查，並於確定違反規定後，依相關規則之規定直接刪除您的用戶內容，或依情節暫時停權或永久停權。
                            </li>
                            <li>如有任何人依據申訴機制向本網站申訴您的用戶內容侵害其個人權利，本網站不需經您的同意，即得先行將您的用戶內容取下，待您與權利人之間的紛爭解決，本網站始能回復您的用戶內容。</li>
                        </ol>
                    </ol>
                    <li>
                        <h2>電子商務服務</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本公司依據本條款提供本網站及本公司所直接運營之第三方商城網站（包括但不限於本服務於綠界科技ECShop所架設之線上商城）上之電子商務服務。</li>
                        <ol className="list-roman">
                            <li>名稱：訊洲科技有限公司</li>
                            <li>負責人：藍澔天</li>
                            <li>
                                電子郵件：
                                <a className="text-primary" href="mailto:contact@offerland.cc" rel="noopener noreferrer" target="_blank">contact@offerland.cc</a>
                            </li>
                            <li>營業地址：台北市文山區辛亥路四段128之1號</li>
                        </ol>
                        <li>
                            本公司直接運營之第三方商城網站（包括但不限於本服務於綠界科技ECShop所架設之線上商城
                            {" "}
                            <a href="https://offerland.cashier.ecpay.com.tw/" rel="noopener noreferrer" target="_blank">https://offerland.cashier.ecpay.com.tw/</a>
                            {" "}
                            ）適用本條款第17條 電子商務服務及第18條 交易與退款政策、損害賠償責任與上限。
                        </li>
                        <li>在您訂購商品前，請詳閱商品規格、交易條件及相關說明，並依照系統交易流程確實填寫相關資訊。</li>
                        <li>第三方金流服務：</li>
                        <ol className="list-roman">
                            <li>本網站認同內容有價的概念，除了販售數位出版品內容外，亦販售相關產品或服務。並提供用戶「訂閱」、「購買」與「贊助」本服務的機制，機制皆由第三方支付公司綠界科技股份有限公司所提供。用戶透過本網站存取第三方支付公司之付費服務時，應同意接受第三方支付公司之服務條款，本網站不會介入處理或干涉您與金流服務廠商間之糾紛。</li>
                            <li>本網站對於第三方支付公司之服務效能及服務品質，不負任何責任及保證。如因第三方支付公司端或用戶所生之事由（包含但不限於：系統異常、盜用冒用、人為操作不當等），造成付款問題時，本網站會協助用戶反映、告知即建議改善，但不負責處理擔保責任及退款事宜。</li>
                            <li>當您使用本公司所直接運營之第三方商城網站（包括但不限於本服務於綠界科技ECShop所架設之線上商城）上「贊助」、「訂閱」或「購買」功能時，本服務會對第三方支付公司發動信用卡授權指示，並不會主動存取您的信用卡資訊，確保過程時資料的隱私與完整性。</li>
                            <li>當您付款時，由第三方支付公司所收取的金流手續費平台將不會退還，且退款時產生的其他費用（如匯款手續費等）由您負擔，超過7天後，若非特殊情形則無法再退款。</li>
                        </ol>
                        <li>您一旦完成訂購程序，就表示您提出要約，願意依照本約定條款及相關網頁上所載明的約定內容、交易條件或限制訂購該商品或服務。您所留存的資料如地址、電話、電子郵件地址若需變更，應於完成下單後儘速通知本網站協助進行相關處理。您得於出貨前取消您的訂單，但不得要求變更其他交易條件。</li>
                        <li>您所登錄之個人資料，除了提供配合廠商處理相關送貨與結帳之用外，本網站將依個人資料保護法規定為適當安全維護管理，不會任意洩漏或提供給第三人。</li>
                        <li>您所訂購的相關商品或服務之品質、保固及售後服務，將載明於銷售頁面，並由提供該商品或服務的第三方服務提供者負責。如有任何爭議，本網站承諾將全力協助溝通、解決。如您無法順利取得前開保固或售後服務，歡迎與本網站聯絡，本網站將協助您確認後續處理程序。</li>
                        <li>本網站內可能包含許多連結，所導引至之網站或網頁上的所有資訊都是由各該網站所提供，與本網站無涉，本網站不以任何明示或默示的方式擔保其內容的正確性、可信度或即時性。</li>
                        <li>本公司全面採用電子發票，不開立紙本發票，當訂單交易完成時，將電子郵件通知您。</li>
                        <li>本網站將會盡商業上合理之努力，維護本網站及交易系統之安全。</li>
                        <li>除本條款外，商品銷售網頁及訂購流程中相關網頁上所呈現之相關資訊，包括相關交易條件、限制及說明等，亦為契約之一部分。</li>
                        <li>出貨政策：</li>
                        <ol className="list-roman">
                            <li>凡購買本服務之實體商品或贈品之方案，將於訂單確認付款後 7 至 30 個工作天出貨。</li>
                            <li>贈品一經拆封，除明顯瑕疵者，則不接受退換貨。</li>
                            <li>贈品屬無償贈送，不接受贈品折現或補差額更換其他商品。</li>
                            <li>若因資料不全、有誤而導致贈品郵件退回，只要您願意自行承擔遞送運費，可透過客服信箱聯繫本網站，我們將會再次為您寄送。</li>
                            <li>若因資料不全、有誤而導致贈品郵件遺失或冒領，恕不另行補發。</li>
                        </ol>
                    </ol>
                    <li>
                        <h2>交易與退款政策、損害賠償責任與上限</h2>
                    </li>
                    <ol className="list-latin">
                        <li className="font-semibold text-red-500">依「通訊交易解除權合理例外情事適用準則」第2條：「非以有形媒介提供之數位內容或一經提供即為完成之線上服務，經消費者事先同意始提供。」故用戶所購買本服務提供之「數位出版品內容」時，將沒有閱覽鑑賞期，敬請在購買前試閱公開內容並審慎評估，恕不提供退換貨服務。</li>
                        <li>
                            本公司將於完成交易，即您收到實體商品七日後無退貨或收到數位出版品內容（無鑑賞期，第 18 條 a 項）時，開立電子發票並寄發E-mail通知您，您可至綠界科技平台或財政部查詢電子發票。退貨、解除交易契約之聯絡管道如下，電子郵件：
                            <a className="text-primary" href="mailto:contact@offerland.cc" rel="noopener noreferrer" target="_blank">contact@offerland.cc</a>
                            。
                        </li>
                        <li>本公司的所有數位出版品內容，皆為出版商或其授權方者的專屬財產，且受到著作權和其他智慧財產相關法律的保護。數位出版品內容僅開放給客戶下載、存取，且僅可作為個人非商業用途使用。凡自本網站下載或存取的數位出版品內容皆嚴格禁止供作任何其他用途使用。您不得修改、傳輸、發佈、參與轉讓或銷售、重製、創作衍生作品、散佈、表演、展示或以任何方式利用任何數位出版品內容的全部或一部。您從本服務下載或存取數位出版品內容即視為您確認且同意本條款。</li>
                        <li>當您完成本網站及本公司所直接運營之第三方商城網站（包括但不限於本服務於綠界科技ECShop所架設之線上商城）上的所有商品或服務之購買，即代表您同意購買當下所顯示的價格、庫存及活動內容。本網站有權因為配合不同階段的服務優化以不同價格出售方案，屬價格正常調動，不提供補差價服務與變更服務內容，還請您諒解。</li>
                        <li>您瞭解並同意，為您辦理退款事宜時，您授權本網站得代您處理發票或折讓單等相關法令所要求之單據。</li>
                        <li>當本網站完成退費時，退費之該筆訂單視為終止。</li>
                        <li>您理解並同意退款需要一定流程及作業時間，本網站將於接獲您同意退款後30個工作天內完成退款作業。</li>
                        <li className="font-semibold text-red-500">因可歸責於本網站之事由，致用戶受有實際損害時，本網站於該事由發生之訂單費用總額內，負擔對用戶之損害賠償責任；惟賠償之範圍，不包含用戶因為協議之完成而生之期待利益，且應以退還有爭議之該筆交易之實收金額為賠償或補償責任之上限。</li>
                        <li className="font-semibold text-red-500">除此協議所述，所有銷售一經售出概不退換。解約退款將視您當初訂購時使用付款方式處理之：</li>
                        <ol className="list-roman">
                            <li>信用卡付款：預計於確認符合解約退款條件後 30 個工作日內將款項刷退至當時使用付款信用卡帳戶中。刷退完成會以 E-mail 通知您。</li>
                            <li>超商條碼付款、ATM虛擬帳號付款：將另行請您提供退款帳戶，預計於確認符合解約退款條件後 30 個工作日內將退款匯入該退款帳戶。匯入完成會以 E-mail 通知您。</li>
                        </ol>
                    </ol>
                    <li>
                        <h2>違反本條款之效果</h2>
                    </li>
                    <ol className="list-latin">
                        <li>如用戶有任何違反本條款之行為，本網站可能採取之措施包括但不限於：</li>
                        <ol className="list-roman">
                            <li>刪除違規內容。</li>
                            <li>暫停違規用戶之帳戶。</li>
                            <li>終止違規用戶之帳戶。</li>
                        </ol>
                        <li>本網站得視用戶違反本條款之情形，自行決定對違規用戶採取上述所列之一部或全部措施。</li>
                        <li>用戶帳戶一但被暫停或被終止，將暫時或永遠無法使用本網站，本網站保留得不預先通知您即暫停或終止您的帳戶之權利。如用戶資格被終止，則用戶可能不再有權查看、變更或刪除您在本網站儲存或上傳之資料。</li>
                        <li>如用戶以任何方式規避應支付本網站之費用，或使其他人規避支付本網站服務費用，用戶應全數支付本網站應得之費用。</li>
                    </ol>
                    <li>
                        <h2>準據法及管轄法院</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本條款的準據法為中華民國法律。</li>
                        <li>雙方就本條款之爭議或歧見，應儘先誠意協商解決，如無法於合理期間內達成協議，雙方同意專屬由臺灣台北地方法院為第一審管轄法院。</li>
                    </ol>
                    <li>
                        <h2>其他</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本條款各條文之標題僅係為閱讀方便而使用，並不影響本條款之內容及解釋。</li>
                        <li>本條款構成您與本網站就您使用本服務之完整合意，為本網站與您之間就使用本服務有關事項之完整協議，並取代先前一切書面或口頭合意。</li>
                        <li>本條款中之任何規定於任何管轄地區被視為無效、違法、或無法強制執行時，僅該部分為無效、違法或無法強制執行，不影響其他條款之效力、合法性或強制性；且該規定於任何管轄地區之無效、違法或無法強制執行時，不影響其於其他管轄地區之效力、合法性或強制性或使之為無效。</li>
                        <li>非經本網站事前書面同意，您不得轉讓本條款之權利義務與任何第三人。</li>
                        <li>本條款並非第三人利益契約，並未賦予任何第三人權利或利益。</li>
                        <li>您於使用或購買特定本網站服務、合作夥伴服務、第三方內容或軟體時，可能亦須適用額外條款或條件。</li>
                        <li>本網站未行使或執行本條款任何權利或規定，不構成前開權利或規定之棄權。若任何本條款規定，經有管轄權之法院認定無效或無法強制執行，當事人仍同意法院應努力使當事人於前開規定所表達之真意生效，亦不會影響本條款其餘條款的效力及執行性。</li>
                        <li>您同意使用您註冊之電子郵件、本網站之通知系統及其他電子或紙本方式作為接收及聯繫本網站之管道。</li>
                        <li>
                            若您對於本條款所述之規範事項存有疑義，請通過本網站之聯繫郵箱(
                            <a className="text-primary" href="mailto:contact@offerland.cc" rel="noopener noreferrer" target="_blank">contact@offerland.cc</a>
                            )提出，本網站對於未依約定要式所提出之疑義，有權拒絕回覆。

                        </li>
                    </ol>
                </ol>
                <p>最後更新：2022 年 12 月 07 日</p>
            </div>
        </div>
    );
}

export default TermsOfService;
