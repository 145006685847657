import ErrorSVG from "../assets/svg/error.svg";

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div className="flex h-screen w-full flex-col items-center justify-center space-y-16 space-x-8 lg:flex-row lg:space-y-0 2xl:space-x-0">
            <div className="flex w-full flex-col items-center justify-center text-center lg:w-1/2 lg:px-2 xl:px-0">
                <p className="text-7xl font-bold tracking-wider text-gray-300 md:text-8xl lg:text-9xl">500</p>
                <p className="mt-2 text-4xl font-bold tracking-wider text-gray-300 md:text-5xl lg:text-6xl">Server Error</p>
                <p className="my-12 text-lg text-gray-500 md:text-xl lg:text-2xl">Whoops, something went wrong on our servers.</p>
                <a href="/">Go Home</a>
                {/* <pre>{error.message}</pre> */}
                {/* <button type="button" onClick={resetErrorBoundary}>Try again</button> */}
            </div>
            <div className="flex w-1/2 justify-center p-4 lg:h-full lg:items-end">
                <img alt="" src={ErrorSVG} />
            </div>
        </div>
    );
}

export default ErrorFallback;
