import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { supabase } from "../utils/supabase";
import { useFlashContext } from "./useFlashContext";

export const useLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setErrorMessage } = useFlashContext();

    const loginWithProvider = async (provider) => {
        const path = location.state?.from || "/";

        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider,
                options: {
                    redirectTo: `${window.location.origin}${path}`,
                },
            });

            if (error) {
                throw error;
            }
        } catch (error) {
            console.log("error", error);
            setError("Something went wrong, please try again later");
        }
    };

    const login = async (values = null) => {
        toast.dismiss();
        setIsLoading(true);

        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: values.email,
                password: values.password,
            });

            if (error) {
                setErrorMessage(error.message);
                return;
            }

            navigate(location.state?.from || "/");
            navigate(0);
        } catch (error) {
            setError("Something went wrong, please try again later");
        } finally {
            setIsLoading(false);
        }
    };

    return {
        error, isLoading, login, loginWithProvider,
    };
};
