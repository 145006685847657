import { Menu, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Fragment } from "react";

import NotificationContainer from "./NotificationContainer";

function NotificationsMenu({ notifications, onNotificationClick, onReadAllClick }) {
    const newNotifications = notifications.filter((notification) => !notification.view);

    return (
        <Menu as="div" className="relative flex">
            <Menu.Button className="flex items-center space-x-3 rounded-full text-sm">
                <div className="cursor-pointer rounded-full p-2 hover:bg-gray-100">
                    <div className="relative items-center rounded text-xs">
                        <span className={classNames(
                            "absolute top-[-5px] right-[-5px] flex h-4 w-4 items-center justify-center rounded-full border bg-red-500 text-xs text-white",
                            newNotifications.length === 0 ? "hidden" : "",
                        )}
                        >
                            <span>{newNotifications.length}</span>
                        </span>
                        <BellIcon className="h-6 w-6 text-gray-500" />
                    </div>
                </div>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute -right-10 z-10 mt-10 max-h-64 w-64 origin-top-right space-y-2 overflow-auto rounded-md bg-white p-2 shadow-lg ring-1 ring-black/5 focus:outline-none">
                    {notifications.length === 0 ? (
                        <div className="flex h-full flex-col items-center justify-center">
                            <span className="text-gray-500">目前沒有通知</span>
                        </div>
                    ) : (
                        <>
                            <div className="flex justify-end pr-2">
                                <button
                                    className={classNames("text-primary hover:opacity-50", newNotifications.length === 0 ? "opacity-50" : "")}
                                    disabled={newNotifications.length === 0}
                                    type="button"
                                    onClick={onReadAllClick}
                                >
                                    全部已讀

                                </button>
                            </div>
                            <div className="grid grid-cols-1 divide-y divide-gray-200">
                                {notifications.map((notification, index) => (
                                    <NotificationContainer
                                        key={notification.id}
                                        notification={notification}
                                        onNotificationClick={onNotificationClick}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

export default NotificationsMenu;
