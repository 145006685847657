import { Popover } from "@headlessui/react";
import * as DOMPurify from "dompurify";
import { Fragment, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import Logo from "../assets/logo.png";
import ProfileDropdown from "../components/Header/ProfileDropdown";
import { useAuthContext } from "../hooks/useAuthContext";
import { useFlashContext } from "../hooks/useFlashContext";
import { useLogout } from "../hooks/useLogout";
import { usePostContext } from "../hooks/usePostContext";
import { countWords } from "../utils/countWords";
import { supabase } from "../utils/supabase";

function EditorHeader() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    let { post_id: postID } = useParams();

    const { user } = useAuthContext();
    const { logout } = useLogout();
    const { title, content, selectedCategory, selectedTags } = usePostContext();
    const { setErrorMessage } = useFlashContext();

    const onClick = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const cleanTitle = DOMPurify.sanitize(title);
        const cleanContent = DOMPurify.sanitize(content);
        console.log(selectedCategory);

        if (!selectedCategory) {
            setErrorMessage("請選擇類別");
            setIsLoading(false);
            return;
        }

        if (cleanTitle.length === 0 || countWords(cleanTitle) === 0) {
            setErrorMessage("請輸入標題");
            setIsLoading(false);
            return;
        }

        if (cleanTitle.length > 100) {
            setErrorMessage("標題字數不可超過100字");
            setIsLoading(false);
            return;
        }

        if (cleanContent.length === 0 || countWords(cleanContent) === 0) {
            setErrorMessage("請輸入內容");
            setIsLoading(false);
            return;
        }

        if (cleanContent.length > 5000) {
            setErrorMessage("內容字數不可超過5000字");
            setIsLoading(false);
            return;
        }

        console.log(selectedCategory);

        try {
            switch (location.pathname) {
            case "/posts/new":
                const { data: newPost, error: newPostError } = await supabase
                    .from("posts")
                    .insert([
                        {
                            title: cleanTitle,
                            content: cleanContent,
                            category_id: selectedCategory.value,
                            user_id: user.id,
                        },
                    ]).select("*");

                if (newPostError) {
                    throw newPostError;
                }

                postID = newPost[0].id;
                break;

            case `/posts/edit/${postID}`:
                const { error: editPostError } = await supabase
                    .from("posts")
                    .update({
                        title: cleanTitle,
                        content: cleanContent,
                        category_id: selectedCategory.value,
                    })
                    .eq("id", postID).select("*");

                if (editPostError) {
                    throw editPostError;
                }

                // Delete all post_tags for this post
                const { error: deletePostTagsError } = await supabase
                    .from("post_tags")
                    .delete()
                    .eq("post_id", postID);

                if (deletePostTagsError) {
                    throw deletePostTagsError;
                }

                break;

            default:
                break;
            }

            // Upsert tags
            const { error: tagsError } = await supabase
                .from("tags")
                .upsert(
                    // tag: { value: 1, label: "#tag (number of post)" }
                    // name should become "tag"
                    selectedTags.map((tag) => ({
                        id: tag.id,
                        name: tag.value,
                    })),
                    {
                        onConflict: "id",
                    },
                );

            if (tagsError) {
                throw tagsError;
            }

            // Insert post_tags
            const { error: postTagsError } = await supabase
                .from("post_tags")
                .insert(
                    selectedTags.map((tag) => ({
                        post_id: postID,
                        tag_id: tag.id,
                    })),
                );

            if (postTagsError) {
                throw postTagsError;
            }
        } catch (error) {
            setErrorMessage("Something went wrong, please try again later");
            return;
        } finally {
            navigate(`/posts/${postID}`);
            setIsLoading(false);
        }
    };

    return (
        <>
            <Popover className="sticky top-0 z-10 w-full bg-white">
                <div className="mx-auto max-w-5xl px-4">
                    <div className="flex h-16 items-center border-b-2 border-gray-100 md:justify-start">
                        <div className="mr-10 flex justify-start">
                            <a className="flex" href="/">
                                <span className="sr-only">OfferLand</span>
                                <img
                                    alt="OfferLand"
                                    className="h-8 w-auto sm:h-10"
                                    src={Logo}
                                />
                            </a>
                        </div>
                        <div className="mr-4 ml-auto flex items-center">
                            <button
                                className="inline-flex h-8 w-16 items-center justify-center rounded-md border border-transparent bg-primary px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:opacity-70"
                                type="submit"
                                onClick={onClick}
                            >
                                {isLoading ? <BeatLoader color="white" size={8} /> : "發佈"}
                            </button>
                        </div>
                        {/* /* Profile dropdown */}
                        {user ? (
                            <ProfileDropdown logout={logout} user={user} />
                        ) : (
                            null
                        )}
                    </div>
                </div>
            </Popover>
            <Outlet />
        </>
    );
}

export default EditorHeader;
