// count chinese words and english words,

export function countWords(input) {
    const str = input.replace(/<[^>]*>/g, "");

    const chinese = str.match(/[\u4e00-\u9fa5]/g);
    const chineseCount = chinese ? chinese.length : 0;

    const english = str.match(/[a-zA-Z]+/g);
    const englishCount = english ? english.length : 0;

    return chineseCount + englishCount;
}
