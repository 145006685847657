import "moment/locale/zh-tw";

import HTML from "html-parse-stringify";
import moment from "moment";

// set the locale to user's timezone
moment.locale();
// moment.locale("zh-tw");

export const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const formatTime = (time) => moment(time).format("YYYY-MM-DD HH:mm:ss");

export const formatDate = (oldDate) => {
    const now = moment();
    const date = moment(oldDate);
    let formattedDate = "";

    if (now.diff(date, "days") >= 7) {
        // if the date is more than 7 days ago, format it as "2月14日04:15"
        formattedDate = date.format("M月D日HH:mm");
    } else {
        // if the date is less than 7 days ago, format it as "1小時" or "2天" etc.
        formattedDate = date.fromNow(true);
    }

    return formattedDate;
};

export const formatPosts = (posts) => posts.map((post) => {
    const formattedDate = formatDate(post.created_at);

    return {
        ...post,
        viewsCount: post.views_count,
        likesCount: post.likes_count,
        commentsCount: post.comments_count,
        tags: post.tags[0] ? post.tags : [],
        date: formattedDate,
        datetime: post.created_at,
        author: {
            id: post.user_id,
            name: post.username,
            avatarUrl: post.avatar_url,
            school: post.school,
            major: post.major,
        },
    };
});

export const stripPosts = (posts) => posts.map((post) => {
    const html = HTML.parse(post.content);
    const firstChild = html[0];
    let hasMore = false;

    if (firstChild.name === "p" && firstChild.children[0].content.length > 100) {
        firstChild.children[0].content = firstChild.children[0].content.slice(0, 100);
        hasMore = true;
    } else if ((firstChild.name === "ul" || firstChild.name === "ol") && firstChild.children.length > 3) {
        firstChild.children = firstChild.children.slice(0, 3);
        hasMore = true;
    }

    return {
        ...post,
        hasMore: html.length > 1 || hasMore,
        content: HTML.stringify([firstChild]),
    };
});

export const formatDegrees = (degrees) => {
    const degreeOptions = [...new Set(degrees.map((degree) => degree.degree))].sort((a, b) => {
        if (a === "Masters") {
            return -1;
        }

        if (b === "Masters") {
            return 1;
        }

        if (a === "MBA") {
            return -1;
        }

        if (b === "MBA") {
            return 1;
        }

        if (a === "Ph.D.") {
            return -1;
        }

        if (b === "Ph.D.") {
            return 1;
        }

        return 0;
    }).map((degree) => ({
        value: degree,
        label: degree,
    }));

    return degreeOptions;
};
