import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { supabase } from "../utils/supabase";
import { useAuthContext } from "./useAuthContext";

export const useLogout = () => {
    const navigate = useNavigate();
    const { dispatch } = useAuthContext();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const logout = async () => {
        toast.dismiss();
        setIsLoading(true);

        try {
            await supabase.auth.signOut();
            localStorage.clear();
            dispatch({
                type: "LOGOUT",
            });
            navigate("/");
            window.location.reload();
        } catch (error) {
            setError("Something went wrong");
        } finally {
            localStorage.clear();
            setIsLoading(false);
        }
    };

    return {
        logout, error, isLoading,
    };
};
