import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BookmarkSquareIcon,
    CalendarIcon,
    LifebuoyIcon,
    PencilSquareIcon,
    Squares2X2Icon,
    XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import Logo from "../assets/logo.png";
import Notifications from "../components/Header/Notifications";
import ProfileDropdown from "../components/Header/ProfileDropdown";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";

const resources = [
    {
        name: "Help Center",
        description: "Get all of your questions answered in our forums or contact support.",
        href: "#",
        icon: LifebuoyIcon,
    },
    {
        name: "Blogs",
        description: "Learn from our experts with our blog posts.",
        href: "#",
        icon: BookmarkSquareIcon,
    },
    {
        name: "Events",
        description: "See what meet-ups and other events we might be planning near you.",
        href: "#",
        icon: CalendarIcon,
    },
    {
        name: "About Us",
        description: "Learn more about us and how we can help you.",
        href: "#",
        icon: Squares2X2Icon,
    },
];

const authNavigations = {
    // signup: {
    //     name: "Sign up", href: "/auth/signup", current: false,
    // },
    login: {
        name: "登入", href: "/auth/login", current: false,
    },
};

const navigations = [
    {
        name: "Create Post",
        href: "/posts/new",
        sub: [],
        current: window.location.pathname === "/posts/new",
    },
    {
        name: "Forum", href: "/posts", sub: [], current: window.location.pathname === "/posts", transName: "論壇",
    },
    {
        name: "Results", href: "/results", sub: [], current: window.location.pathname === "/results", transName: "錄取結果",
    },
    {
        name: "Marketplace", href: "/marketplace", sub: [], current: window.location.pathname === "/marketplace", transName: "商城",
    },
    {
        name: "More", href: "/more", sub: resources, current: window.location.pathname === "/more", transName: "更多",
    },
];

function Header() {
    const { user, isAuthenticating } = useAuthContext();
    const { logout } = useLogout();

    return (
        isAuthenticating ? (
            <div className="flex h-onePage items-center justify-center">
                <BeatLoader color="#1E40AF" />
            </div>
        ) : (
            <>
                <Popover className="sticky top-0 z-10 w-full bg-white">
                    <div className="mx-auto max-w-7xl px-4">
                        <div className="flex h-16 items-center justify-between">
                            <div>
                                <a className="flex h-full" href="/">
                                    <span className="sr-only">OfferLand</span>
                                    <img
                                        alt="OfferLand"
                                        className="h-8 sm:h-10"
                                        src={Logo}
                                        title="OfferLand"
                                    />
                                </a>
                            </div>
                            <div className="-my-2 -mr-2 md:hidden">
                                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                                    <span className="sr-only">Open menu</span>
                                    <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                                </Popover.Button>
                            </div>
                            <Popover.Group as="nav" className="hidden items-center space-x-12 md:flex">
                                {navigations.map((navigation) => (navigation.sub.length === 0 ? (
                                    <a
                                        key={navigation.name}
                                        className={classNames(
                                            navigation.current ? "text-primary" : "text-gray-500",
                                            "text-sm font-medium  hover:text-primary",
                                        )}
                                        href={navigation.href}
                                    >
                                        {navigation.name === "Create Post"
                                            ? (<PencilSquareIcon className="h-5 w-5" />)
                                            : (
                                                <div className="flex flex-col items-center">
                                                    <span>{navigation.transName}</span>
                                                    <span>{navigation.name}</span>
                                                </div>
                                            )}
                                    </a>
                                ) : (
                                    <div key={navigation.name}>
                                        <Popover className="relative">
                                            {({ open }) => (
                                                <>
                                                    <Popover.Button
                                                        className={classNames(
                                                            open ? "text-gray-900" : "text-gray-500",
                                                            "flex items-center rounded-md bg-white text-sm font-medium hover:text-primary focus:outline-none",
                                                        )}
                                                    >
                                                        <div className="flex flex-col items-center">
                                                            <span>{navigation.transName}</span>
                                                            <span>{navigation.name}</span>
                                                        </div>
                                                        <ChevronDownIcon
                                                            aria-hidden="true"
                                                            className={classNames(
                                                                open ? "text-gray-600" : "text-gray-400",
                                                                "ml-2 h-5 w-5 group-hover:text-gray-500",
                                                            )}
                                                        />
                                                    </Popover.Button>

                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="opacity-0 translate-y-1"
                                                        enterTo="opacity-100 translate-y-0"
                                                        leave="transition ease-in duration-150"
                                                        leaveFrom="opacity-100 translate-y-0"
                                                        leaveTo="opacity-0 translate-y-1"
                                                    >
                                                        <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md">
                                                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                                    {navigation.sub.map((item) => (
                                                                        <a
                                                                            key={item.name}
                                                                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                                                                            href={item.href}
                                                                        >
                                                                            <item.icon
                                                                                aria-hidden="true"
                                                                                className="h-6 w-6 text-primary"
                                                                            />
                                                                            <div className="ml-2">
                                                                                <p className="text-base font-medium text-gray-900">
                                                                                    {item.name}
                                                                                </p>
                                                                                <p className="mt-1 text-sm text-gray-500">
                                                                                    {item.description}
                                                                                </p>
                                                                            </div>
                                                                        </a>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Popover.Panel>
                                                    </Transition>
                                                </>
                                            )}
                                        </Popover>
                                    </div>
                                )))}
                            </Popover.Group>
                            {/* /* Profile dropdown */}
                            {user ? (
                                <div className="hidden items-center space-x-3 md:flex">
                                    <Notifications />
                                    <ProfileDropdown logout={logout} user={user} />
                                </div>
                            ) : (
                                <div className="hidden items-center md:flex">
                                    <a
                                        className="mr-8 inline-flex items-center justify-center whitespace-nowrap rounded-full border-2 border-primary bg-white px-8 py-2 text-base font-medium text-primary shadow-sm hover:bg-primary hover:text-white"
                                        href={authNavigations.login.href}
                                    >
                                        {authNavigations.login.name}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute inset-x-0 top-0 origin-top-right p-2 transition md:hidden"
                        >
                            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black/5">
                                <div className="px-5 pt-5 pb-6">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <a className="flex focus:outline-none" href="/">
                                                <span className="sr-only">OfferLand</span>
                                                <img
                                                    alt=""
                                                    className="h-8 w-auto"
                                                    src={Logo}
                                                />
                                            </a>
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                                                <span className="sr-only">Close menu</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <nav className="justify-center space-y-8">
                                            {navigations.map((navigation) => (navigation.sub.length === 0 ? (
                                                <a
                                                    key={navigation.name}
                                                    className={classNames(
                                                        navigation.current ? "text-primary" : "text-gray-500",
                                                        "flex justify-center text-base font-medium  hover:text-primary",
                                                    )}
                                                    href={navigation.href}
                                                >
                                                    {navigation.name === "Create Post"
                                                        ? (
                                                            <div className="flex items-center">
                                                                <PencilSquareIcon className="ml-2 h-5 w-5" />
                                                            </div>
                                                        )
                                                        : (
                                                            <div className="flex flex-col">
                                                                <span>{navigation.transName}</span>
                                                            </div>
                                                        )}
                                                </a>
                                            ) : (
                                                <div key={navigation.name} className="flex justify-center">
                                                    <Popover className="relative">
                                                        {({ open }) => (
                                                            <>
                                                                <div className="w-full">
                                                                    <Popover.Button
                                                                        className={classNames(
                                                                            open ? "text-gray-900" : "text-gray-500",
                                                                            "flex w-100 items-center rounded-md bg-white text-base font-medium hover:text-primary focus:outline-none",
                                                                        )}
                                                                    >
                                                                        <span>{navigation.transName}</span>
                                                                        <ChevronDownIcon
                                                                            aria-hidden="true"
                                                                            className={classNames(
                                                                                open ? "text-gray-600" : "text-gray-400",
                                                                                "ml-2 h-5 group-hover:text-gray-500 hover:text-primary",
                                                                            )}
                                                                        />
                                                                    </Popover.Button>
                                                                </div>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-200"
                                                                    enterFrom="opacity-0 translate-y-1"
                                                                    enterTo="opacity-100 translate-y-0"
                                                                    leave="transition ease-in duration-150"
                                                                    leaveFrom="opacity-100 translate-y-0"
                                                                    leaveTo="opacity-0 translate-y-1"
                                                                >
                                                                    <Popover.Panel className="absolute z-10 -ml-48 mt-3 w-screen max-w-md">
                                                                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                                                {navigation.sub.map((item) => (
                                                                                    <a
                                                                                        key={item.name}
                                                                                        className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                                                                                        href={item.href}
                                                                                    >
                                                                                        <item.icon
                                                                                            aria-hidden="true"
                                                                                            className="h-6 w-6 text-primary"
                                                                                        />
                                                                                        <div className="ml-2">
                                                                                            <p className="text-base font-medium text-gray-900">
                                                                                                {item.name}
                                                                                            </p>
                                                                                            <p className="mt-1 text-sm text-gray-500">
                                                                                                {item.description}
                                                                                            </p>
                                                                                        </div>
                                                                                    </a>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </Popover.Panel>
                                                                </Transition>
                                                            </>
                                                        )}
                                                    </Popover>
                                                </div>
                                            )))}
                                        </nav>
                                    </div>
                                </div>
                                {user ? (
                                    <div className="flex items-center justify-center space-x-3 py-4 md:hidden">
                                        <Notifications />
                                        <ProfileDropdown logout={logout} user={user} />
                                    </div>

                                ) : (
                                    <div className="space-y-6 py-3 px-8">
                                        <a
                                            className="flex w-full items-center justify-center px-4 py-2 text-base font-medium text-primary hover:opacity-60"
                                            href={authNavigations.login.href}
                                        >
                                            {authNavigations.login.name}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
                <Outlet />
            </>
        )
    );
}

export default Header;
