import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../../hooks/useAuthContext";
import { useFlashContext } from "../../../hooks/useFlashContext";
import { supabase } from "../../../utils/supabase";
import NotificationsMenu from "./NotificationsMenu";

function NotificationsContainer() {
    const [notifications, setNotifications] = useState([]);
    const { user } = useAuthContext();
    const { setErrorMessage } = useFlashContext();
    const navigate = useNavigate();

    useEffect(() => {
        const getNotifications = async () => {
            try {
                const { data, error } = await supabase.rpc("get_user_post_notifications", {
                    user_id: user.id,
                }).order("created_at", {
                    ascending: false,
                });
                console.log(data);

                if (error) {
                    throw error;
                }

                setNotifications(data);
            } catch (error) {
                setErrorMessage("Something went wrong, please try again later.");
            }
        };

        if (user) {
            getNotifications();
        }
    }, [user]);

    const handleNotificationClick = useCallback(async (notification) => {
        try {
            const { data, error } = await supabase.from("notifications").update({
                view: true,
            }).eq("id", notification.id);

            if (error) {
                throw error;
            }
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            navigate(`/posts/${notification.post_id}`);
            navigate(0);
        }
    }, []);

    const handleReadAllClick = useCallback(async () => {
        try {
            const { data, error } = await supabase.from("notifications").update({
                view: true,
            }).eq("to", user.id);

            if (error) {
                throw error;
            }
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setNotifications((notifications) => notifications.map((notification) => ({
                ...notification, view: true,
            })));
        }
    }, []);

    return (
        <NotificationsMenu
            notifications={notifications}
            onNotificationClick={handleNotificationClick}
            onReadAllClick={handleReadAllClick}
        />
    );
}

export default NotificationsContainer;
