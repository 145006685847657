import { EyeSlashIcon } from "@heroicons/react/24/outline";

function RevealPassword({ revealPassword, setRevealPassword }) {
    const onClick = () => {
        setRevealPassword(!revealPassword);
    };

    return (
        <EyeSlashIcon
            className="absolute right-6 top-4 h-4 w-4 cursor-pointer"
            onClick={onClick}
        />
    );
}

export default RevealPassword;
