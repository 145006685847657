function NotFound() {
    return (
        <div className="flex h-onePage items-center px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <div className="mx-auto max-w-max">
                <main className="sm:flex">
                    <p className="text-4xl font-bold tracking-tight text-primary sm:text-5xl">404</p>
                    <div className="sm:ml-6">
                        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                Page not found
                            </h1>
                            <p className="mt-1 text-base text-gray-500">
                                Please check the URL in the address bar and try again.
                            </p>
                        </div>
                        <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                            <a
                                className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-md hover:opacity-70"
                                href="/"
                            >
                                回到首頁
                            </a>
                            <a
                                className="inline-flex items-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-md hover:opacity-70"
                                href={`mailto: contact@offerland.cc?subject=[404ERROR] page=${window.location.href}`}
                            >
                                回報錯誤
                            </a>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default NotFound;
