import { createContext, useMemo, useState } from "react";

export const PostContext = createContext();

export function PostProvider({ children }) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);

    const value = useMemo(() => ({
        title,
        setTitle,
        content,
        setContent,
        selectedCategory,
        setSelectedCategory,
        selectedTags,
        setSelectedTags,
    }), [title, content, selectedCategory, selectedTags]);

    return (
        <PostContext.Provider value={value}>
            {children}
        </PostContext.Provider>
    );
}
