import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import { lazy, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from "react-toastify";

import ErrorFallback from "./components/ErrorFallback";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import { AuthProvider } from "./context/AuthContext";
import { FlashProvider } from "./context/FlashContext";
import { PostProvider } from "./context/PostContext";
import { ReCaptchaProvider } from "./context/RecaptchaContext";
import EditorHeader from "./layouts/EditorHeader";
import Header from "./layouts/Header";
import LoginAdmin from "./pages/LoginAdmin";
import NotFound from "./pages/NotFound";
import { compose } from "./utils/compose";
import PrivateRoutes from "./utils/PrivateRoutes";
import PublicRoutes from "./utils/PublicRoutes";

const NewEditPost = lazy(() => import("./pages/NewEditPost"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ForgotPasswordReset = lazy(() => import("./pages/ForgotPasswordReset"));
const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Posts = lazy(() => import("./pages/Posts"));
const Profile = lazy(() => import("./pages/Profile"));
const Results = lazy(() => import("./pages/Results"));
const Saved = lazy(() => import("./pages/Saved"));
const Settings = lazy(() => import("./pages/Settings"));
const Signup = lazy(() => import("./pages/Signup"));
const SinglePost = lazy(() => import("./pages/SinglePost"));
// initialize google analytics if in production
// if (process.env.NODE_ENV === "prod") {
//     ReactGA.initialize(process.env.REACT_APP_GA4_ID);
//     ReactGA.send("pageview");
// }

function App() {
    // Set backgroundColor of the body
    useEffect(() => {
        document.body.style.backgroundColor = "#F8F8F8";
        document.body.style.overflowY = "overlay";
    }, []);

    // Compose all the providers
    const Provider = compose([ReCaptchaProvider, HelmetProvider, FlashProvider, AuthProvider, PostProvider]);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ToastContainer
                closeOnClick
                draggable
                pauseOnFocusLoss
                pauseOnHover
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                position="top-center"
                rtl={false}
                theme="light"
            />
            <Router>
                <Suspense fallback={(
                    <div className="flex h-onePage items-center justify-center">
                        <BeatLoader color="#1E40AF" />
                    </div>
                )}
                >
                    <Provider>
                        <Routes>
                            <Route element={<PrivateRoutes />}>
                                <Route element={<EditorHeader />}>
                                    <Route element={<NewEditPost />} path="/posts/new" />
                                    <Route element={<NewEditPost />} path="/posts/edit/:post_id" />
                                </Route>
                            </Route>
                            <Route element={<Header />}>
                                <Route element={<Home />} path="/" />
                                <Route element={<Posts />} path="/posts" />
                                <Route element={<NotFound />} path="*" />
                                <Route element={<TermsOfService />} path="/terms-of-service" />
                                <Route element={<PrivacyPolicy />} path="/privacy-policy" />

                                <Route element={<PublicRoutes />}>
                                    <Route element={<Login />} path="/auth/login" />

                                    <Route element={<Signup />} path={`/auth/signup/${process.env.REACT_APP_ADMIN_SECRET}`} />
                                    <Route element={<LoginAdmin />} path={`/auth/login/${process.env.REACT_APP_ADMIN_SECRET}`} />
                                    <Route element={<ForgotPassword />} path="/auth/forgot-password" />
                                </Route>

                                <Route element={<PrivateRoutes />}>
                                    <Route element={<ForgotPasswordReset />} path="/auth/reset-forgot-password" />
                                    <Route element={<Profile />} path="/profile/:username" />
                                    <Route element={<Saved />} path="/saved" />
                                    <Route element={<SinglePost />} path="/posts/:post_id" />
                                    <Route element={<Results />} path="/results" />
                                    <Route element={<Settings />} path="/settings" />
                                </Route>
                            </Route>
                        </Routes>
                    </Provider>
                </Suspense>
            </Router>
        </ErrorBoundary>
    );
}

export default App;
