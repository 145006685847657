import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import { useAuthContext } from "../hooks/useAuthContext";
import { useFlashContext } from "../hooks/useFlashContext";

function PrivateRoutes() {
    const location = useLocation();
    const { setErrorMessage } = useFlashContext();
    const { user, isAuthenticating } = useAuthContext();

    useEffect(() => {
        if (!isAuthenticating && !user) {
            setErrorMessage("請先登入");
        }
    }, [isAuthenticating, user, location]);

    // eslint-disable-next-line no-nested-ternary
    return isAuthenticating ? (
        <div className="flex h-onePage items-center justify-center">
            <BeatLoader color="#1E40AF" />
        </div>
    )
        : user ? <Outlet /> : (
            <Navigate
                replace
                state={{
                    from: location.pathname,
                }}
                to="/auth/login"
            />
        );
}

export default PrivateRoutes;
