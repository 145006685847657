import { Menu } from "@headlessui/react";
import classNames from "classnames";

import { formatDate } from "../../../utils/format-util";

function NotificationContainer({ notification, onNotificationClick }) {
    return (
        <Menu.Item key={notification.id} className="py-4 first:pt-4 last:pb-4">
            {({ active }) => (
                <button
                    className={classNames(
                        active ? "bg-gray-100" : "",
                        "flex flex-col w-full px-2 space-y-2 text-sm text-gray-700 h-20 hover:bg-gray-100 rounded-md",
                    )}
                    type="button"
                    onClick={() => onNotificationClick(notification)}
                >
                    <span className="max-h-8 text-start">
                        <span className={classNames(
                            notification.view ? "text-gray-500" : "text-gray-600",
                        )}
                        >
                            {/* restrict 10 words only */}
                            {notification.post_title.replace(/<[^>]+>/g, "").split(" ").slice(0, 10).join(" ")}
                        </span>
                        <span className={classNames(
                            "ml-2",
                            notification.view ? "text-gray-400" : "text-gray-600",
                        )}
                        >
                            有一則新回覆
                        </span>
                    </span>
                    <time
                        className={classNames(notification.view ? "text-gray-400" : "text-primary")}
                        dateTime={notification.created_at}
                    >
                        {formatDate(notification.created_at)}
                    </time>

                </button>
            )}
        </Menu.Item>
    );
}

export default NotificationContainer;
