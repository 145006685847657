import { Navigate, Outlet, useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import { useAuthContext } from "../hooks/useAuthContext";

function PublicRoutes() {
    const { user, isAuthenticating } = useAuthContext();
    const location = useLocation();

    if (isAuthenticating) {
        return (
            <div className="flex h-onePage items-center justify-center">
                <BeatLoader color="#1E40AF" />
            </div>
        );
    }

    return user ? (
        <Navigate
            state={{
                from: location.pathname,
            }}
            to="/"
        />
    ) : <Outlet />;
}

export default PublicRoutes;
