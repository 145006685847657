import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFlashContext } from "../hooks/useFlashContext";
import { supabase } from "../utils/supabase";

export const AuthContext = createContext();

const authReducer = (state, action) => {
    switch (action.type) {
    case "LOGIN":
        return {
            user: action.payload,
        };
    case "LOGOUT":
        return {
            user: null,
        };
    default:
        return state;
    }
};

export function AuthProvider({ children }) {
    const navigate = useNavigate();
    const { setErrorMessage } = useFlashContext();
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
    });

    const [isAuthenticating, setIsAuthenticating] = useState(true);

    useEffect(() => {
        // If get user from local storage and user is not null
        // if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) {
        //     const user = JSON.parse(localStorage.getItem("user"));
        //     dispatch({
        //         type: "LOGIN",
        //         payload: user,
        //     });
        //     setIsAuthenticating(false);
        //     return;
        // }

        const checkUser = async () => {
            try {
                const { data: { user } } = await supabase.auth.getUser();

                if (user) {
                    const { data: profiles, error } = await supabase
                        .from("profiles")
                        .select("*")
                        .eq("id", user.id)
                        .single();

                    if (error) {
                        console.log(error);
                        setErrorMessage("Something went wrong, please try again later");
                        return;
                    }

                    dispatch({
                        type: "LOGIN",
                        payload: profiles,
                    });

                    // localStorage.setItem("user", JSON.stringify(userProfile));
                } else {
                    dispatch({
                        type: "LOGOUT",
                    });

                    // localStorage.removeItem("user");
                }
            } catch (error) {
                console.log(error);
                setErrorMessage("Something went wrong, please try again later");
            } finally {
                setIsAuthenticating(false);
            }
        };

        checkUser();
    }, []);

    useEffect(() => {
        supabase.auth.onAuthStateChange(async (event, session) => {
            if (event === "PASSWORD_RECOVERY") {
                navigate("/auth/reset-forgot-password");
            }
        });
    }, []);
    // useEffect(() => {
    //     supabase.auth.onAuthStateChange(async (event, session) => {
    //         if (event === "SIGNED_IN") {
    //             const { data: { user } } = await supabase.auth.getUser();
    //             dispatch({
    //                 type: "LOGIN",
    //                 payload: user,
    //             });
    //             const createdAt = new Date(user.created_at);
    //             const lastSign = new Date(user.last_sign_in_at);
    //             const date1String = createdAt.toISOString().slice(0, -5);
    //             const date2String = lastSign.toISOString().slice(0, -5);

    //             switch (user.app_metadata.provider) {
    //             case "google":
    //                 // if (date1String === date2String) {
    //                 //     await axios.post("/users", {
    //                 //         user_id: user.id,
    //                 //         email: user.user_metadata.email,
    //                 //         username: user.user_metadata.full_name,
    //                 //         avatar_url: user.user_metadata.avatar_url,
    //                 //     });
    //                 // }

    //                 navigate(location.state?.from || "/", {
    //                     replace: true,
    //                 });
    //                 break;
    //             case "email":
    //                 // if created_at and last_sign_in_at is smaller than 15 minutes,
    //                 // then it's a new user, since we set confirmation time to 15 minutes
    //                 if (createdAt.getTime() - lastSign.getTime() < 900000) {
    //                     // await axios.post("/auth/email-signup", {
    //                     //     user_id: session.user.id,
    //                     //     email: session.user.email,
    //                     // });
    //                 }

    //                 // navigate to the previous page
    //                 navigate(location.state?.from || "/", {
    //                     replace: true,
    //                 });
    //                 window.location.reload();
    //                 break;
    //             default:
    //                 break;
    //             }
    //         } else if (event === "SIGNED_OUT") {
    //             dispatch({
    //                 type: "LOGOUT",
    //             });
    //         }
    //     });
    // }, []);

    // useEffect(() => {
    //     const unsubscribe = onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             dispatch({
    //                 type: "LOGIN",
    //                 payload: user,
    //             });
    //         } else {
    //             dispatch({
    //                 type: "LOGOUT",
    //             });
    //         }

    //         setIsAuthenticating(false);
    //     });

    //     return unsubscribe();
    // }, [auth]);

    const value = useMemo(
        () => ({
            ...state,
            dispatch,
            isAuthenticating,
        }),
        [state, dispatch, isAuthenticating],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
