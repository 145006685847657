/* eslint-disable max-len */
function PrivacyPolicy() {
    return (
        <div className="my-12 flex justify-center">
            <div className="prose prose-sm focus:outline-none sm:prose-base">
                <h1 className="text-center"><strong>隱私權政策</strong></h1>
                <p>OfferLand（下稱「本網站」）所提供所有 OfferLand 相關產品與服務（下稱「本服務」）尊重且重視用戶的隱私權，訊洲科技有限公司（下稱「本公司」）盡可能在本服務中保護用戶的個人資料，本服務將以中華民國「個人資料保護法」之規範，訂定隱私權政策，當您註冊或使用本服務時，即表示您同意本網站收集、使用、移轉、揭露及儲存，以及按照本隱私權政策所述方式處理您的個人資料及其他資訊。</p>
                <p>此於使用本服務前，請您詳細閱讀「隱私權政策」（下稱「本政策」），並隨時注意本服務所公告之相關修改或變更，本服務有權於任何時間修改或變更本政策之內容。當您使用本服務時，或於任何修改或變更後繼續使用本服務，即表示您已閱讀、瞭解並同意接受本政策，或該等修改或變更後之內容，及同意本服務依據本政策蒐集、處理與利用您的個人資料；如果您無法遵守或不同意本政策之內容，或您所屬的國家、地區排除本政策內容之全部或一部時，請您立即停止使用本服務。</p>
                <p>
                    若您有著作權、財產權、隱私權等疑慮，或其他智慧財產權、法律上權利遭受侵害之疑慮，建議透過以下信箱與我們聯繫：
                    <a className="text-primary" href="mailto:contact@offerland.cc" rel="noopener noreferrer" target="_blank">contact@offerland.cc</a>
                    。
                </p>
                <p>此外，本服務於未經您許可的情形下，絕不會將您的個人資料提供予任何第三方。本服務可能於臺灣以外之地區蒐集、處理、利用您的個人資料，且將受到實體及技術安全等裝置的保護，若您反對此蒐集、處理、利用之方式，亦請您立即停止使用本網站。</p>
                <ol>
                    <li>
                        <h2>名詞定義</h2>
                    </li>
                    <ol className="list-latin">
                        <li>用戶：指使用本服務的用戶，不論其身份為訪客或已註冊之使用者。</li>
                        <li>用戶內容：用戶所創作並上傳、儲存、發布至本網站的著作權、文章及資料，以及由用戶公開張貼或私下傳送的訊息、數據、文字、軟體、音樂、音頻、照片、圖形、影片、代碼或其他資訊等內容。</li>
                        <li>廣告商：即廣告公司，係指從事廣告代理業務，為其客戶創造、規劃、或執行廣告工作，其工作範圍可能涵蓋曝光、内容和戰略等方面，為客戶的產品或服務銷售提供外部服務。</li>
                        <li>合作夥伴：包括但不限於廣告商、第三方服務提供者、本公司客戶、本公司供應商。</li>
                        <li>本網站所直接運營之第三方網站：包括但不限於本服務於綠界科技ECShop所架設之線上商城。</li>
                        <li>本網站所經營之社群媒體：包括但不限於本網站所經營之部落格、Facebook、Instagram、LinkedIn、Line@、Discord或其他社群媒體。</li>
                        <li>教學媒合：本網站提供之教學媒合服務，包括但不限於留學顧問、文書服務、語言教學媒合等。教學服務完全由第三方服務提供者所提供，而非由本公司提供，本公司將作為平台/媒合方促使第三方提升教學服務之品質。本公司提供之教學媒合服務，並非教學服務協議之任一方當事人。第三方亦非本公司之雇員或代理人。</li>
                        <li>第三方服務提供者：係指教學媒合之第三方服務提供者，包括但不限於老師、潤稿業者、留遊學代辦機構、補習班、顧問等。</li>
                        <li>數位出版品內容：係指本網站發布、上傳或銷售之非以有形媒介提供之數位內容，包括但不限於電子書、線上課程等。</li>
                    </ol>
                    <li>
                        <h2>對於本政策之同意</h2>
                    </li>
                    <ol className="list-latin">
                        <li>如您拒絕提供部分個人資料，您可能無法完成註冊程序，或無法使用部分本服務。</li>
                        <li>本公司服務並非針對13歲以下的兒童設計，或以13歲以下的兒童為服務對象，未滿13歲使用者無法註冊為本網站會員。</li>
                        <li>本網站將依法盡商業上合理之努力保護您的個人資料及隱私。您瞭解並確認本網站已經依據個人資料保護法第 8 條第 1 項明確告知與個人資料保護有關事項。</li>
                        <li>若您為未成年人，請您在您法定代理人或監護人偕同閱讀並了解本政策下使用本公司提供之服務。當您使用本公司相關服務並提供個人資料，視為您、您的法定代理人或監護人已閱覽並同意本公司依本政策從事個人資料蒐集、處理及利用。</li>
                    </ol>
                    <li>
                        <h2>適用範圍</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本政策適用於您在本網站活動時，所涉及的個人資料蒐集方法、處理程序與利用範圍。</li>
                        <li>
                            本政策適用於本服務於
                            {" "}
                            <a className="text-primary" href="https://offerland.cc" rel="noopener noreferrer" target="_blank">https://offerland.cc</a>
                            {" "}
                            網站域名及次級網域名與各項服務（包括但不限於行動裝置、桌上型電腦等）以及「本網站所直接運營之第三方網站」所提供的服務。
                        </li>
                        <li>本政策不適用於本服務以外的相關連結網站，及非本網站所委託或參與管理的人員。</li>
                        <li>本服務對於個人資料之蒐集、處理及利用，以誠實及信用方法在目的範圍內運用，不會損害到您的權益。本服務保存您的個人資料，在您主張取消時，本服務即進行您個人資料的移除作業。</li>
                    </ol>
                    <li>
                        <h2>用戶權利</h2>
                    </li>
                    <ol className="list-latin">
                        <li>
                            就您的個人資料，您依法享有以下權利，其中關於補充或更正個人資料部分，本網站可能會需要請您提供相關資料以確認您資訊更新的真實性，請以電子郵件寄至：
                            <a className="text-primary" href="mailto:contact@offerland.cc" rel="noopener noreferrer" target="_blank">contact@offerland.cc</a>
                            ，本網站將於收悉後與您聯繫及處理。您理解並同意，為了保護您的隱私，本網站可能於您行使本項權利時，以查驗身份證明文件，或其他本網站認為適合的方式，驗證請求人的身份，且依個人資料保護法規定，於您查詢或請求閱覽個人資料或製給複製本時，本網站得向您酌收必要成本費用：
                        </li>
                        <ol className="list-roman">
                            <li>查詢或請求閱覽。</li>
                            <li>請求製給複製本。</li>
                            <li>請求補充或更正。</li>
                            <li>請求停止蒐集、處理或利用。</li>
                            <li>請求刪除。惟本網站仍會以無法直接識別您個人的方式，保留您的手機、Email、ID 等不重複資料，確保同樣資料不會再次被註冊使用。</li>
                        </ol>
                        <li>若您在使用本公司服務期間居住於歐盟境內，依照歐盟一般個人資料保護規則，您得向本公司行使以下權利：</li>
                        <ol className="list-roman">
                            <li>您得隨時撤回對於本公司蒐集處理您個人資料之同意，惟同意之撤回不影響本公司在您撤回前基於該同意所為處理之合法性。</li>
                            <li>在本公司有將您個人資料公開且有依據您請求刪除個人資料之情形下，本公司將考量現有科技及執行成本，在合理範圍內通知其他個資控管者刪除該個人資料連結。</li>
                            <li>您就本公司經您同意或依據契約關係而以自動化方式取得之個人資料，享有資料可攜權。</li>
                            <li>
                                您有向監管機關提起申訴之權利，聯繫資料：
                                <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en" rel="noopener noreferrer" target="_blank">https://edpb.europa.eu/about-edpb/about-edpb/members_en</a>
                                。
                            </li>
                        </ol>
                        <li>本公司受理您依前兩項提出請求時，得以適當方式確認您的身分。另本公司得考量您所要求提供之資訊與本公司配合提供各項請求之作業成本，收取必要費用。</li>
                        <li>若您拒絕提供本公司完成註冊所必要之資料，本公司將無法完成註冊流程並提供會員服務；若您請求本公司停止蒐集、處理或利用您於服務過程所提供予本公司之資料，本公司將無法依據您需求完整地提供服務，而可能影響服務品質；若您請求本公司刪除您所有個人資料檔案，本公司將因無法繼續提供服務而併同終止您會員資格。</li>
                    </ol>
                    <li>
                        <h2>個人資料之蒐集類別及使用方式</h2>
                    </li>
                    <p>本服務蒐集個人資料之主要目的，在於為用戶提供順暢與最符合用戶需要的服務及功能。以下為個人資料蒐集類別、本服務所採取的資料處理方式：</p>
                    <ol className="list-latin">
                        <li>依據中華民國「個人資料保護法」之特定目的及個人資料之類別，本服務可能蒐集的個人資料類別為：</li>
                        <ol className="list-roman">
                            <li>Ｃ○○一 辨識個人者：姓名、職稱、住址、工作地址、以前地址、住家電話號碼、行動電話、即時通帳號、網路平臺申請之帳號、通訊及戶籍地址、相片、指紋、電子郵遞地址、電子簽章、憑證卡序號、憑證序號、提供網路身分認證或申辦查詢服務之紀錄及其他任何可辨識資料本人者等。</li>
                            <li>Ｃ○一一&nbsp; 個人描述：年齡、性別、出生年月日、出生地、國籍、聲音等。</li>
                            <li>Ｃ○一二&nbsp; 身體描述：身高、體重、血型等。</li>
                            <li>Ｃ○一三&nbsp; 習慣：抽煙、喝酒等。</li>
                            <li>Ｃ○一四&nbsp; 個性：個性等之評述意見。</li>
                            <li>Ｃ○二一&nbsp; 家庭情形：結婚有無、配偶或同居人之姓名、前配偶或同居人之姓名、結婚之日期、子女之人數等。</li>
                            <li>Ｃ○二三&nbsp; 家庭其他成員之細節：子女、受扶養人、家庭其他成員或親屬、父母、同居人及旅居國外及大陸人民親屬等。</li>
                            <li>Ｃ○二四&nbsp; 其他社會關係：朋友、同事及其他除家庭以外之關係等。</li>
                            <li>Ｃ○三一&nbsp; 住家及設施：住所地址、設備之種類、所有或承租、住用之期間、租金或稅率及其他花費在房屋上之支出、房屋之種類、價值及所有人之姓名等。</li>
                            <li>Ｃ○三二&nbsp; 財產：所有或具有其他權利之動產或不動產等。</li>
                            <li>Ｃ○三三&nbsp; 移民情形：護照、工作許可文件、居留證明文件、住居或旅行限制、入境之條件及其他相關細節等。</li>
                            <li>Ｃ○三四&nbsp; 旅行及其他遷徙細節：過去之遷徙、旅行細節、外國護照、居留證明文件及工作證照及工作證等相關細節等。</li>
                            <li>Ｃ○三五&nbsp; 休閒活動及興趣：嗜好、運動及其他興趣等。</li>
                            <li>Ｃ○三六&nbsp; 生活格調：使用消費品之種類及服務之細節、個人或家庭之消費模式等。</li>
                            <li>Ｃ○三八&nbsp; 職業：學校校長、民意代表或其他各種職業等。</li>
                            <li>Ｃ○三九&nbsp; 執照或其他許可：駕駛執照、行車執照、自衛槍枝使用執照、釣魚執照等</li>
                            <li>Ｃ○五一&nbsp; 學校紀錄：大學、專科或其他學校等。</li>
                            <li>Ｃ○五二&nbsp; 資格或技術：學歷資格、專業技術、特別執照（如飛機駕駛執照等）、政府職訓機構學習過程、國家考試、考試成績或其他訓練紀錄等。</li>
                            <li>Ｃ○五三&nbsp; 職業團體會員資格：會員資格類別、會員資格紀錄、參加之紀錄等。</li>
                            <li>Ｃ○五四&nbsp; 職業專長：專家、學者、顧問等。</li>
                            <li>Ｃ○五六&nbsp; 著作：書籍、文章、報告、視聽出版品及其他著作等。</li>
                            <li>Ｃ○五七&nbsp; 學生（員）、應考人紀錄：學習過程、相關資格、考試訓練考核及成績、評分評語或其他學習或考試紀錄等。</li>
                            <li>Ｃ○六一&nbsp; 現行之受僱情形：僱主、工作職稱、工作描述、等級、受僱日期、工時、工作地點、產業特性、受僱之條件及期間、與現行僱主有關之以前責任與經驗等。</li>
                            <li>Ｃ○六二&nbsp; 僱用經過：日期、受僱方式、介紹、僱用期間等。</li>
                            <li>Ｃ○六三&nbsp; 離職經過：離職之日期、離職之原因、離職之通知及條件等。</li>
                            <li>Ｃ○六四&nbsp; 工作經驗：以前之僱主、以前之工作、失業之期間及軍中服役情形等。</li>
                        </ol>
                        <li>本服務會／可能於下列時候收集您的個人資料，包括但不限於：</li>
                        <ol className="list-roman">
                            <li>當您註冊及/或使用本服務時。</li>
                            <li>當您透過電話 (可能會錄音)、信件、傳真、面對面會議、社交媒體平台和電子郵件等方式與本網站互動時，包括與本網站的客服人員互動時。</li>
                            <li>當您在行動裝置上允許分享資訊給本網站的應用程式或服務時。</li>
                            <li>當您透過本服務進行交易時。</li>
                            <li>當您提供意見或投訴給本網站時。</li>
                            <li>當您透過本服務與本網站或他人互動時。</li>
                            <li>當您基於任何原因提交用戶內容或個人資料給本網站時。</li>
                        </ol>
                        <li>本服務會在您使用本服務的必要範圍內，收集用戶的個人資料，包括但不限於：</li>
                        <ol className="list-roman">
                            <li>姓名、電子郵件地址、使用及交易紀錄，包括您的搜尋、訂單內容，您在本服務上的用戶內容、留言、分享。</li>
                            <li>您向本公司提供的資訊，如：您於註冊用戶時提供本公司的聯絡信箱，以及您於使用本服務時設定的個人檔案資料。</li>
                            <li>您使用本服務的相關資訊，如：您發布的文字與對話內容、您回報的錄取結果／留學申請落點（包括但不限於您的畢業院校、科系、成績、錄取學校、語言檢定成績等）。</li>
                            <li>您使用本服務的活動資訊，如：您如何使用本網站的服務、您的活動和互動的時間、頻率以及持續時間。</li>
                            <li>本公司在註冊流程中，蒐集您自願提交的個人資料，包含姓名、性別、生日、照片、國籍、居住區域、手機門號、電子郵件、社群帳號等資料。</li>
                            <li>本公司會在您使用本服務時，蒐集您的需求、所處時區、語言能力、課程評價、透過本公司與老師會員間之課程安排與溝通情形、客服申訴紀錄、意見調查、好友推薦與相關使用情形，以及在您同意下為錄製個別課程教學內容以供您後續利用。</li>
                            <li>為維護課程品質、優化教學成效及降低教學爭議，本公司提供您同步錄製課程之選項，當您的教師與您均選擇同意錄影並開放下載檔案時，您可下載該次教學影片作為課後複習使用；若您同意錄影、但您的教師不同意開放檔案下載，則該次教學影片由本公司備份，限於雙方嗣後同意或有其他正當事由，始開放存取。錄影功能選項為預設開啟並可在預約個別課程時選擇關閉，若您未關閉，即視為同意錄影，錄影功能均套用先前設定，若您希望了解詳細操作，可參閱本公司網站。</li>
                        </ol>
                        <li>若您使用您的社群媒體帳號，如 Facebook 來註冊成為本服務用戶，或將您於本服務的帳號與您的社群媒體帳號連結，本網站會存取您於該社群媒體帳號提供者之政策下，自願提供予該社群媒體帳號提供者的資訊，如：User ID、用戶名稱、Email，本服務將持續依照本隱私權政策來管理及使用相關個人資料。</li>
                        <li>您同意本公司於您註冊會員時蒐集您所提供的個人資料，並透過網站管理工具蒐集您在本公司網站或行動應用程式之活動紀錄。此外，若您以Facebook、Line等社群軟體帳號登入使用本公司所提供之服務，或是綁定諸如Google、Telegram等社群平台帳號作為收受通知使用，本公司會從社群網站或軟體提供者蒐集您的公開資料。</li>
                        <li>您在註冊帳號、瀏覽本服務內容頁面、參與問答言論發表時，本網站將會收集你的個人資料，本網站也可以從其他合作夥伴處（如 Facebook 、Google）取得您的個人資料。</li>
                        <li>本服務會對於您的個人資料進行以下處理，包括但不限於：</li>
                        <ol className="list-roman">
                            <li>將 User ID 與本服務帳號綁定。</li>
                            <li>將 User ID 紀錄於本服務資料庫中。</li>
                        </ol>
                        <li>本服務對於您個人資料處理的目的，包括但不限於：</li>
                        <ol className="list-roman">
                            <li>辨識用戶是否註冊。</li>
                            <li>接收消費行為通知。</li>
                            <li>系統公告或異常之聯繫。</li>
                            <li>便利其他用戶在本服務上與您互動或聯繫。</li>
                            <li>提供用戶登入畫面顯示名稱。</li>
                        </ol>
                        <li>
                            若您不希望本服務收集前述資訊/個人資料，您可以隨時透過 Email （
                            <a className="text-primary" href="mailto:contact@offerland.cc" rel="noopener noreferrer" target="_blank">contact@offerland.cc</a>
                            ），告知您選擇退出，本網站將於 15 個工作天內正式回覆該申請。不過請注意，若您不允許或是不同意本服務收集、使用或處理您的個人資料，您對本服務的使用可能會受到影響，如無法使用本服務部分功能。
                        </li>
                        <li>您同意不提供任何錯誤或誤導的資訊給本服務，並且您同意在您所提供的資訊有任何錯誤或變更時通知本網站。本服務保留單方決定向您要求進一步文件以驗證您所提供的資料之權利。</li>
                        <li>當您在註冊時，本網站會根據您所註冊之會員不同，要求最低需求量之個人資料。於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的 IP 位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，此記錄為內部應用，絕不對外公布。</li>
                        <li>本公司如有違反個資法規定蒐集、處理或利用您之個人資料，依個資法第十一條第四項規定，您得向本公司請求停止蒐集、處理與利用。</li>
                    </ol>
                    <li>
                        <h2>與第三者共用個人資料之政策</h2>
                    </li>
                    <p>除非事前經過您的同意或本公司基於履行契約而有委託合作廠商處理以外，本公司不會將您的個人資料提供予第三人。就您個人資料利用對象之類型，說明如下：</p>
                    <ol className="list-latin">
                        <li>本公司委託之線上客服系統、雲端儲存服務、身分驗證及支付服務解決方案合作廠商。</li>
                        <li>本公司會將您的訂單編號與金額提供予本公司合作之金流服務供應商，以利您使用其服務完成線上付款。當您購買商品或服務後，網頁會跳轉至金流服務供應商之付款頁面，所有支付流程均在該頁面執行，本公司原則上不會蒐集到您的信用卡或支付帳戶資料。</li>
                        <li>
                            本公司採用Google Analytics等網站管理工具，而有將您瀏覽紀錄提供予此類廠商作為分析、改善網站設計之用。有關Google Analytics如何蒐集與處理您的資料，可參考其說明
                            {" "}
                            <a href="https://policies.google.com/technologies/partner-sites" rel="noopener noreferrer" target="_blank">https://policies.google.com/technologies/partner-sites</a>
                            。若您不願將網站瀏覽資料提供給Google Analytics，您得安裝Google提供之瀏覽器
                            <a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">外掛程式</a>
                            。
                        </li>
                    </ol>
                    <p>本網站絕不會任意出售、交換、或出租任何您的個人資料給其他團體或個人。只有在下列情況除外，這些狀況包括（但不限於）：</p>
                    <ol className="list-latin">
                        <li>在您同意分享資訊的情況下。</li>
                        <li>當您行為已違反網站政策，或可能損害或妨礙本網站導致任何人遭受損害，經本網站研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。</li>
                        <li>基於善意相信揭露您的個人資料為法律所需要。</li>
                        <li>司法單位因公眾安全，要求公開特定個人資料時，將視司法單位適法性及是否遵照法定之程序，以及考量對所有使用者安全，採行可能必要的配合措施。</li>
                    </ol>
                    <li>
                        <h2>第三方資料蒐集</h2>
                    </li>
                    <ol className="list-latin">
                        <li>
                            本公司網站或行動應用程式可能包含與其他網站的連結，例如老師會員之個人履歷網頁、金流服務供應商付款頁面或合作廠商活動網站等，這些網站不在本政策適用範圍，請您在離開本公司服務頁面、拜訪第三方網站前，自行閱讀並評估其隱私權政策。
                            <span className="font-semibold text-red-500">該第三方網站有可能蒐集您的資料、IP 位址或以 Cookie 等技術蒐集您的資料，本網站不負責保障您在第 三方網站或使用其服務的隱私權。您於第三方網站之一 切行為，皆與本網站無涉，如因此遭受任何損害，均不得向本網站求償。</span>
                        </li>
                        <li>當您透過本服務下載教學影片或取得他人含有個人資料之檔案時，此屬於您個人蒐集處理活動，非本政策適用範圍。若您有逾越單純個人或家庭活動目的範圍之處理利用時，您即可能適用個人資料保護法相關規定，應自行承擔法律義務與責任。</li>
                    </ol>
                    <li>
                        <h2>蒐集目的與依據</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本公司蒐集您於註冊流程或與本公司互動過程所提供之個人資料之目的如下：</li>
                        <ol className="list-roman">
                            <li>為履行與您之間電子商務與教學媒合服務等契約所必要，需建立並管理您的個人檔案，以達成會員身分辨識、行銷、資料庫管理、客戶與消費者服務、會計帳務作業之目的。</li>
                            <li>為確保本服務品質、對於您使用本服務提供獎勵或優惠活動，或提供您更優質與客製化之服務。</li>
                            <li>基於經營營業登記項目或章程所定之業務，或為履行法定義務所進行個人資料之蒐集處理及利用。</li>
                        </ol>
                        <li>本公司蒐集處理您個人資料之依據，包含：</li>
                        <ol className="list-roman">
                            <li>經您閱讀本政策所載特定目的與相關告知事項後所為同意。</li>
                            <li>基於履行與您之間契約所必要。</li>
                            <li>履行本公司法定義務，例如基於稅務目的保留相關交易憑證。</li>
                            <li>維護本公司服務品質與確保其他使用者之權益，例如防止詐欺或犯罪活動，並保護本公司資訊系統。</li>
                        </ol>
                        <li>本公司系統將自動蒐集與紀錄下列個人資料：</li>
                        <ol className="list-roman">
                            <li>您向本公司提供的資訊，如：您於註冊會員時提供本公司的聯絡信箱，以及您於使用本服務時設定的個人檔案資料。</li>
                            <li>您使用本服務情況的相關資訊，如：您發布的文字與對話內容。</li>
                            <li>您使用本服務的活動資訊，如：您如何使用我們的服務、您的活動和互動的時間、頻率以及持續時間。</li>
                        </ol>
                        <li>本網站蒐集您的個人資料，以提供您本服務，特定目的可能包括：</li>
                        <ol className="list-roman">
                            <li>行銷 （特定目的項目 040）</li>
                            <li>會員或其他成員名冊之內部管理（特定目的項目 052）</li>
                            <li>契約、類契約或其他法律關係事務（特定目的項目 069）</li>
                            <li>消費者、客戶管理與服務（特定目的項目 090）</li>
                            <li>產學合作（特定目的項目110）</li>
                            <li>資（通）訊服務（特定目的項目 135）</li>
                            <li>資（通）訊與資料庫管理（特定目的項目 136）</li>
                            <li>圖書館、出版品管理（特定目的項目 146）</li>
                            <li>網路購物及其他電子商務服務（特定目的項目 148）</li>
                            <li>廣告或商業行為管理（特定目的項目 152）</li>
                            <li>影視、音樂與媒體管理（特定目的項目 153）</li>
                            <li>調查、統計與研究分析（特定目的項目 157）</li>
                            <li>學生（員）（含畢、結業生）資料管理（特定目的項目 158）</li>
                            <li>其他經營合於營業登記項目或組織章程所定之業務（特定目的項目 181）</li>
                            <li>其他諮詢與顧問服務（特定目的項目 182）</li>
                            <li>以及其他與本服務相關的利用，比如為了瞭解您的使用情形，本網站可能單獨邀請您前來本公司接受訪談，或是經您另外明確的同意以後，與第三方廠商共同辦理行銷活動。</li>
                        </ol>
                        <li>因您使用本服務的程度不同，本網站可能因主動（請注意，在您使用本網站服務時，即便您未登入，本網站仍然可能會辨識出您或您的裝置）或被動（如您自行於發表文章或回文時揭露），蒐集您的註冊資料、使用 本網站 服務的活動記錄，包括但不限於您的所在位置、閱讀文章的喜好、與其他使用者的互動（包括留言與站內信）、上網的 IP 位址、裝置資訊、使用時間、瀏覽及點選記錄及其他 本網站 因您使用服務所取得之一切資訊等，實際的資料端視您的使用情形。</li>
                    </ol>
                    <li>
                        <h2>利用期間及地區</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本網站將於營運期間持續保存您的個人資料，本網站將所蒐集之個人資料，儲存於本網站購買或租用的儲存空間，實際儲存地點可能因伺服器位置不同而改變（可能包括臺灣、新加坡及日本），您同意本網站無須為了單純的伺服器儲存位置改變而對您為通知。</li>
                        <li>本網站的使用者可能於全球任何地點登入並使用本網站 務，因此您理解並同意，於此範圍內本網站得於全球地區利用您的個人資料，以完善提供本網站服務。</li>
                        <li>本公司於前開特定目的存續期間內，於本公司營運地區──包含但不限於香港、臺灣及本公司其他服務據點，與委外廠商所在地區利用您其他個人資料檔案。</li>
                        <li>您瞭解並同意本公司基於履行契約所必要，會在歐盟境外處理您的個人資料，並傳送到未經歐盟執委會認定具有個資適足性之地區，惟本公司會依據當地法規採取適當安全維護措施，以維護您個資安全。</li>
                    </ol>
                    <li>
                        <h2>利用對象及方式</h2>
                    </li>
                    <ol className="list-latin">
                        <li>本網站及其合作夥伴將以電子、書面、電話、電信、網際網路及其他方式，於蒐集之特定目的範圍內處理並利用或國際傳輸您的個人資料。於特殊情形，您的資料可能基於您的同意而為本網站以外的廠商所蒐集與利用，詳請參見 I 條之約定。此外，本網站於下列情形，亦可能將您的個人資料提供給第三人、或超出本政策之特定目的必要範圍使用：</li>
                    </ol>
                    <ol className="list-roman">
                        <li>受司法、警察或其他有權機關基於調查之要求時。</li>
                        <li>於維護公益或保護本網站或他人權益時。</li>
                        <li>因會員之行為違反法令或您與本網站間的契約（不限於本協議）規定時</li>
                        <li>在緊急情況下為維護其他會員或第三人之人身、交易內容、個人資料之完整與安全時。</li>
                        <li>其他合法情形（比如於發生著作權爭議時，本網站可能基於您明確的同意，將主張權利一方的資料，提供予被主張侵害權利的一方）。</li>
                    </ol>
                    <li>
                        <h2>廣告和其他行銷內容</h2>
                    </li>
                    <ol className="list-latin">
                        <li>您瞭解為了永續經營，本網站接受廣告業務，您並同意在使用本網站服務時接受廣告和其他行銷內容。我們可能在您存取內含網路信標、開發工具、Cookie 和其他技術資料蒐集資訊。這些資料蒐集技術有助於我們瞭解您的活動情形，以改進我們的產品與服務，並提供您您有興趣的廣告資訊。這些資訊包括所提供、觀看或點選的內容或廣告類型；您的活動頻率與使用持續時間；您是否與特定內容或廣告有所互動；以及您是否接著造訪廣告商的網站、下載廣告商的應用程式、購買廣告中的產品或服務或者採取其他行動。</li>
                        <li>為了提供對您而言有價值的廣告和其他行銷內容，您同意本網站可以分析您的個人資料、用戶內容及帳戶資料，予以分類，將無法直接辨識您個人之資訊或族群類別提供予廣告商，以便廣告商可以選擇要投放廣告的族群類別。</li>
                        <li>
                            本網站可能會與廣告商共同辦理行銷活動，並可能邀請您參與。您於參加此類行銷活動中，可能必須另外同意該活動之活動辦法，且可能必須將您的個人資料提供予該活動之合作夥伴，亦即，您可能因參與活動，而與該第三方廠商間產生獨立的契約關係，您應於參加前詳讀參加條款，並瞭解第三方廠商可能如何利用您的
                            個人資料。您理解並同意，本網站 無法介入您與第三方廠商間的法律關係。
                        </li>
                    </ol>
                    <li>
                        <h2>Cookie 運用政策</h2>
                    </li>
                    <ol className="list-latin">
                        <li>Cookie 是伺服端為了區別使用者的不同喜好，經由瀏覽器寫入使用者硬碟的一些簡短資訊。只要修改瀏覽器設定，你可以選擇接受所有 cookie、設定 cookie 時得到通知、拒絕所有 cookie 等三種。</li>
                        <li>依據以下目的及情況，本網站會在本政策原則之下，在您瀏覽器中寫入並讀取 cookie：為提供更好的個人化服務，以及方便您參與個人化的互動活動。Cookie 在您註冊或登入時建立，並在您登出時修改。為統計瀏覽人數及分析瀏覽模式，以了解網頁瀏覽的情況，做為本網站改善服務的參考。</li>
                        <li>Cookie是從本公司網站伺服器傳送到您的瀏覽器並保存在您的電腦硬碟中的資料，本公司使用Cookie作為與您溝通與辨識的工具，目的在記錄個人先前瀏覽資料，以利於更有效率地回復頁面、提供個人化服務或投放符合您興趣及偏好的服務項目或廣告。若您不希望接收本公司所運用的Cookie，您可以透過所使用的瀏覽器更改Cookie設定。如您選擇不接受或阻擋本公司Cookie，可能會導致完全無法使用或僅能使用本公司為您提供的部分服務功能，亦可能無法使用部份個人化服務，或是參與部份的活動。</li>
                    </ol>
                    <li>
                        <h2>資料之保護</h2>
                    </li>
                    <p>本網站主機均設有防火牆、防毒系統、重要個資加密等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料。</p>
                    <li>
                        <h2>個人帳號之保密</h2>
                    </li>
                    <p>請妥善維護您的會員帳號、密碼或任何個人資料。請您留意無論何時您透過網站問答區、電子郵件、或聊天室等公開區域所主動揭露的個人資料，均可能被其他第三人蒐集或使用，上網時請特別小心。</p>
                    <li>
                        <h2>用戶資料之刪除</h2>
                    </li>
                    <p>本網站依照本政策妥善處理本網站用戶個人之隱私資料及權益，當本網站用戶來信提出刪除會員之要求，並要求刪除用戶個資情況下，經本網站人員確認用戶身份後，將於15個工作日內進行刪除用戶資料流程，完成刪除作業程序後，將會回信告知用戶，本網站絕不會將個人資料做任何保留，用戶亦可隨時與本服務聯絡確認之。</p>
                    <ol className="list-latin">
                        <li>當用戶向本網站提出刪除用戶個資，本網站將會協助刪除的數據包括但不限於：使用本服務時設定的個人檔案資料，如聯絡信箱、學歷等；使用本服務期間的所有權利，如積分、積分使用明細、系統通知、參加活動紀錄、VIP 或會員資格等；以及使用本服務的活動資訊，如活動和互動的時間、頻率以及持續時間等。</li>
                        <li>本服務將不會主動刪除用戶所投稿的內容與問題等，若有必要請事先以手動方式刪除。</li>
                        <li>當有以下情形，本網站會協助刪除用戶個人資料（包括但不限於）：（I）平台數據不再需要為用戶提供應用體驗或服務時（II）當用戶請求時（III）法律或法規要求時。</li>
                        <li>以下情形，本網站將以停止蒐集、處理或利用個人資料之方式代替刪除個人資料：（I）有法令規定（II）有契約約定之保存期限（III）有理由說明刪除時將侵害當事人保護利益。</li>
                        <li>若您使用第三方平台（如 Facebook、Gmail、Apple ID）註冊或登入本網站，以下情形本網站將會協助刪除用戶個人資料（包括但不限於）：（I）當用戶刪除與您的應用關聯的帳號時（II）因個人使用狀況導致第三方平台提出要求時。此外，當您主動向第三方平台提出刪除帳號時，若該帳號為註冊、登入本網站之帳號，您於本網站的資訊也會一併遭到刪除（包括您所購買的內容），您將會失去所有現有的用戶權益。</li>
                    </ol>
                    <p>
                        本網站用戶如欲行使上述權利，可與本網站聯絡，透過Email:
                        <a className="text-primary" href="mailto:contact@offerland.cc" rel="noopener noreferrer" target="_blank">contact@offerland.cc</a>
                        進行申請。
                    </p>
                    <li>
                        <h2>其他、隱私保護政策的修改</h2>
                    </li>
                    <ol className="list-latin">
                        <li>由於科技發展的迅速，相關法規訂定未臻完備前，以及未來可能難以預見的環境變遷等因素，將會視需要修改網站上所提供的隱私權保護政策，並於網頁上公告，以落實保障您個人資料保護的立意。如果您不同意本政策，請立即停止使用本網站服務。</li>
                        <li>本公司得不定時依據業務需求與法令變遷修訂本政策，若本公司通知並公告修正後您仍繼續使用本公司服務，視為接受修訂之版本。</li>
                        <li>本公司於蒐集、控制、處理、傳輸或以其他方式使用您的個人資料時，可能受公司營運所在地之法律、規則或要求拘束。若本政策之規定與其他公司營運所在地適用之法律有衝突或差異時，本公司將採取就個人資料保護更嚴格之要求與條件。</li>
                        <li className="font-semibold text-red-500">「第三方服務提供者隱私權政策補充事項」，就您成為本服務之第三方服務提供者所涉個人資料蒐集、處理及利用，請參閱此隱私權政策補充事項（下稱為「本事項」)，本事項未有告知或約定部分，適用本政策。</li>
                        <ol className="list-roman font-semibold text-red-500">
                            <li>本公司蒐集個人資料之內容與目的：本公司為提供您媒合教學對象、文章撰寫、採訪專欄等服務，需蒐集您自願提供之學經歷、履歷、工作經驗、照片、證照或證書、自我介紹短片、教學示範短片及其他有助於您宣傳之參考資料。</li>
                            <li>本公司利用您個人資料之方式</li>
                            <ol>
                                <li>本公司會將您的個人資料與學生給予您的評價紀錄，製作為您基本檔案，在本公司服務頁面展示並提供使用者查詢與閱覽。</li>
                                <li>本公司會以您的個人檔案（包括但不限於，您自願提供之照片、影片、文字介紹等資料），在本公司或由「本網站所經營之社群媒體」頁面展示並投放給本公司粉絲或追蹤者，以作為行銷使用。</li>
                                <li>本公司會以您的個人檔案（包括但不限於，您自願提供之照片、影片、文字介紹等資料），委由廣告商Google投放至合作網站之指定廣告版位予不特定使用者，作為行銷使用。</li>
                                <li>若您對本公司利用您個人資料之方式有任何意見，請透過信箱與本網站聯繫。</li>
                            </ol>
                        </ol>
                    </ol>
                </ol>
                <h2>我有疑問、意見、擔擾</h2>
                <p>若您對本政策或本網站的其他隱私權政策或行為存在任何疑問、意見或擔擾，請透過本網站相關聯絡資訊與本網站取得聯繫。 您同意您的本網站帳號係不可轉讓，且您本網站帳號或帳號中內容之任何權利，於您死亡時即立刻終止。於本網站接獲您的死亡證明文件時，您的帳號將被終止，且其所有內容將被永久刪除。本政策之標題僅系為方便起見，不具任何法律或契約效果。若有任何違反本政策之行為，請立即與本網站聯絡。</p>
                <p>
                    聯繫信箱：
                    <a className="text-primary" href="mailto:contact@offerland.cc" rel="noopener noreferrer" target="_blank">contact@offerland.cc</a>
                </p>
                <p>最後更新：2022 年 12 月 07 日</p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
