import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

// disable console.log in production
if (process.env.REACT_APP_NODE_ENV === "prod") { console.log = () => {}; }

createRoot(document.getElementById("root")).render(
    <App />,
);
